import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { AppMenuSecondService } from 'src/app/services/menus/second.service';
import { VanService } from 'src/app/services/stocks/van.service';

@Component({
  selector: '',
  templateUrl: './warehouse-detail-page.component.html',
  styleUrls: ['./warehouse-detail-page.component.scss'],
})
export class WarehouseDetailPage extends BasePage implements OnInit {
  public id: number = 0;

  constructor(private _vanService: VanService, private _secondMenu: AppMenuSecondService) {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getPathParams({ id: 0 }).subscribe(({ id }) => {
        this._setPageInformation({
          level: EPageLevel.Details,
          name: 'stock.pages.detail',
        });

        this.id = id;
      })
    );
    this.fetch();
  }

  public fetch() {
    if (!this.id) {
      return;
    }
    this.requestState.asked();
    this._vanService.fetchWarehouseDetail$({ vanId: this.id }).subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }

  public getWarehouseMenu() {
    return this._secondMenu.getWarehouseMenu({ warehouseId: this.id });
  }
}
