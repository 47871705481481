<cam-layout-page>
  <cam-layout-header>
    <cam-layout-header-default [title]="this.pageTitle"></cam-layout-header-default>
  </cam-layout-header>
  <cam-layout-content>
    <cam-loader [isLoading]="this.requestState.isLoading()" skeleton="fileList">
      <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
        <cam-guard feature="person" level="read">
          <div class="list row">
            <div *ngIf="this.personId">
              <ng-container [ngSwitch]="this.scope">
                <ng-container *ngSwitchCase="'offer-requests'">
                  <app-offer-requests [personId]="this.personId"></app-offer-requests>
                </ng-container>
                <ng-container *ngSwitchCase="'contact-persons'">
                  <app-contacts [personId]="this.personId"></app-contacts>
                </ng-container>
                <ng-container *ngSwitchCase="'working-sites'">
                  <app-working-sites [personId]="this.personId"></app-working-sites>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </cam-guard>
      </cam-error>
    </cam-loader>
  </cam-layout-content>
  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
