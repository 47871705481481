import { Component, OnInit } from '@angular/core';

import { User } from '@camelot/services';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { AppUsersService } from 'src/app/services/users/users.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserPage extends BasePage implements OnInit {
  public userId: number | null = null;
  public user!: User;

  constructor(public usersService: AppUsersService) {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getPathParams({ id: 0 }).subscribe(({ id }) => {
        this._setPageInformation({
          level: EPageLevel.Details,
          name: 'tiers.pages.detail.title',
        });

        this.userId = id ?? null;
      })
    );
  }
}
