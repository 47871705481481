<app-layout-first-level *ngLet="this.profileName$ | async as profile">
  <app-layout-title>
    <div class="align-center g-space-sm">
      <cam-user-logo [userInfo]="profile" [size]="'md'" (click)="this.openAccount()"></cam-user-logo>
      <cam-title [level]="2">{{ 'base.home.title' | translate }}</cam-title>
    </div>
  </app-layout-title>
  <app-layout-content>
    <ng-template #menuTemplate>
      <app-menu-user></app-menu-user>
    </ng-template>

    <div class="cards-container">
      <ng-container *ngIf="(this._userService.isAuthenticated$ | async) === false">
        <cam-hello-user [title]="'base.home.hello'" [footer]="'base.home.guest'"></cam-hello-user>
        <app-disconnected-card></app-disconnected-card>
      </ng-container>

      <cam-guard
        *ngIf="this.authService.isAuthenticated$ | async"
        level="authenticated"
        [canDisplayErrorMessage]="false"
      >
        <div class="cards-container">
          <cam-title class="mt-space-md" [level]="3">
            {{ 'base.home.hello' | translate }} {{ profile.naming?.firstName }} 👋
          </cam-title>

          <app-daily-visits></app-daily-visits>

          <app-daily-reminders *ngIf="this.canAccessReminders"></app-daily-reminders>

          <cam-guard level="read" feature="worker" [canDisplayErrorMessage]="false">
            <app-working-site-home [status]="this.productionVoucherStatus.ToAdjust"></app-working-site-home>
            <app-working-site-home [status]="this.productionVoucherStatus.Delayed"></app-working-site-home>
          </cam-guard>
        </div>
      </cam-guard>

      <div class="row">
        <div class="col-12 col-md-6 mb-xs" *ngIf="this.authService.isAuthenticated$ | async">
          <app-teams-support></app-teams-support>
        </div>
      </div>
    </div>
  </app-layout-content>
  <app-layout-nav>
    <app-menu-main></app-menu-main>
  </app-layout-nav>
</app-layout-first-level>

<ng-template #accountTemplate>
  <app-menu-user></app-menu-user>
</ng-template>
