<cam-loader [isLoading]="this.requestState.isLoading()" *ngLet="this.profile$ | async as profile">
  <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
    <cam-empty [isEmpty]="!profile">
      <div class="pb-space-sm row user-info bdp-b color-border-primary">
        <cam-inline-profile-data
          [profile]="profile ?? {}"
          [userLogo]="this.userLogo$ | async"
        ></cam-inline-profile-data>
      </div>
    </cam-empty>
  </cam-error>
</cam-loader>

<ng-container *ngIf="this.isAuthenticated$ | async">
  <div *ngIf="this.profileMenu" class="bdp-b color-border-primary pt-space-xs">
    <cam-menu [menu]="this.profileMenu" [style]="'dark'"></cam-menu>
  </div>

  <div *ngIf="this.disconnectionMenu" class="bdp-b pt-space-xs">
    <cam-menu [menu]="this.disconnectionMenu" [style]="'dark'"></cam-menu>
  </div>

  <div class="ta-c">
    <small>Version: {{ this.version }}</small>
  </div>
</ng-container>

<ng-template #languageTemplate>
  <cam-loader [isLoading]="this.changeLanguageAsked">
    <div class="mx-space-sm">
      <cam-list-container>
        <ng-container *ngFor="let language of this.languages">
          <cam-list-element [withSeparator]="false" (click)="this.changeLanguage(language.id)">
            <cam-list-title class="d-flex p-space-xs">
              {{ language.name | translate }}
            </cam-list-title>
            <cam-list-tag *ngIf="language.id === this.activeLanguage">
              <cam-font-icon name="check-line" class="color-semantic-token-success"></cam-font-icon>
            </cam-list-tag>
          </cam-list-element>
        </ng-container>
      </cam-list-container>
    </div>
  </cam-loader>
</ng-template>
