import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';

import { BehaviorSubject, Observable, Subject, map, switchMap } from 'rxjs';

import { InputBase } from '@camelot/form-model';
import { Menu } from '@camelot/menu';
import { MenuBase } from '@camelot/menu';
import { FileData } from '@camelot/utils';
import { FileStructure } from '@camelot/utils';

import { ENotificationCode } from 'src/app/core/enum-type/notification';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { DetailParams } from 'src/app/modules/visits/types';
import { AppChecklistsService } from 'src/app/services/checklists/checklists.service';
import { Checklist } from 'src/app/services/checklists/dto/checklist';
import { AppChecklistFormService } from 'src/app/services/checklists/form.service';
import { Picture } from 'src/app/services/files/dto/picture';
import { AppVisitsService } from 'src/app/services/visits/visits.service';

@Component({
  selector: 'app-checklist-sections',
  templateUrl: './checklist-sections.component.html',
  styleUrls: ['./checklist-sections.component.scss'],
})
export class ChecklistSectionsComponent extends BaseComponent implements OnInit {
  @Input()
  id!: number;

  @Input()
  visitParams!: DetailParams;

  public askValidation$ = new Subject<null>();
  public form: InputBase<any>[] = [];
  public sectionSelected$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public menu: Menu = new Menu({
    elements: [],
    direction: 'horizontal',
  });

  public checklistData: Checklist | null = null;

  get elementsPerPage(): number {
    if (this.menu.elements.length < 2.5) return this.menu.elements.length;

    return 2.5;
  }

  private _saveStatus: 'save' | 'send' | null = null;

  constructor(
    private _checklistsService: AppChecklistsService,
    private _checklistFormService: AppChecklistFormService,
    private _serviceVisit: AppVisitsService
  ) {
    super();
  }

  ngOnInit() {
    this._registerSubscription(
      this._checklistsService
        .getChecklistSection$(this.id, this.visitParams.id)
        .subscribe(sections => this._addSections(sections))
    );
    this._registerSubscription(
      this._checklistsService
        .getChecklist$(this.id, this.visitParams.id)
        .subscribe(checklist => this.updateForm(checklist))
    );
    this.fetch();
  }

  public askSave() {
    this._saveStatus = 'save';
    this.askValidation$.next(null);
  }
  public askSend() {
    this._saveStatus = 'send';
    this.askValidation$.next(null);
  }
  public askValidation() {
    this.askValidation$.next(null);
  }
  public fetch() {
    this.requestState.asked();
    this._serviceVisit.fetchPics$(this.visitParams).subscribe();
    this._checklistsService
      .fetchCheckList$({
        id: this.id,
        visitId: this.visitParams.id,
        visitType: this.visitParams.type,
      })
      .subscribe({
        complete: () => {
          this.requestState.completed();
        },
        error: (error: HttpErrorResponse) => {
          this.requestState.onError(error.status, error.statusText);
        },
      });
  }

  public tabSelected(tab: string | null) {
    this.sectionSelected$.next(tab);
  }

  public updateForm(checklist: Checklist) {
    this.checklistData = checklist;
    this.form = this._checklistFormService.getChecklistForm(checklist, this.sectionSelected$, {
      upload: this._uploadImage,
      get$: this._getPics$(),
    });
  }

  public validForm(data: any) {
    if (!this.checklistData) {
      return;
    }
    const filledChecklist = this._checklistFormService.fillChecklistByForm(this.checklistData, data);
    if (this._saveStatus === 'save') {
      this.save(filledChecklist);
    } else if (this._saveStatus === 'send') {
      this.send(filledChecklist);
    } else {
      this.save(filledChecklist);
    }

    this._saveStatus = null;
  }
  public save(filledChecklist: Checklist) {
    this.requestState.asked();
    this._save(filledChecklist).subscribe({
      complete: () => {
        this._notificationService.addNotification('checklist.sections.save.success.message', ENotificationCode.success);
        this.requestState.completed();
      },
      error: () => {
        this._notificationService.addNotification('checklist.sections.error.message', ENotificationCode.error);
        this.requestState.completed();
      },
    });
  }

  public send(filledChecklist: Checklist) {
    this.requestState.asked();
    this._save(filledChecklist)
      .pipe(
        switchMap(() =>
          this._checklistsService.sendCheckList$({
            id: this.id,
            visitId: this.visitParams.id,
            visitType: this.visitParams.type,
          })
        )
      )
      .subscribe({
        complete: () => {
          this._notificationService.addNotification(
            'checklist.sections.send.success.message',
            ENotificationCode.success
          );
          this.requestState.completed();
        },
        error: () => {
          this._notificationService.addNotification('checklist.sections.error.message', ENotificationCode.error);
          this.requestState.completed();
        },
      });
  }

  private _save(filledChecklist: Checklist): Observable<never | void> {
    return this._checklistsService.postCheckList$({
      ...filledChecklist,
      ...{ visitId: this.visitParams.id, visitType: this.visitParams.type },
    });
  }
  private _addSections(sections: { id: number; name: string }[]) {
    this.menu.elements = sections.map(
      section =>
        new MenuBase({
          key: section.id.toString(),
          label: section.name,
        })
    );
    if (this.menu.elements[0]) {
      this.tabSelected(this.menu.elements[0]?.key);
    }
  }

  private _uploadImage = async (pics: FileStructure[]): Promise<Picture[]> => {
    return new Promise((resolve, reject) => {
      this._serviceVisit
        .uploadFiles$({
          files: <File[]>pics.map(pic => pic.file).filter(file => !!file),
          id: this.visitParams.id,
          type: this.visitParams.type,
        })
        .subscribe({
          next: data => {
            resolve(data);
          },
          complete: () => {
            this._notificationService.addNotification('sandbox.pics.upload.success.message', ENotificationCode.success);
            this._serviceVisit.fetchPics$(this.visitParams).subscribe();
          },
          error: () => {
            this._notificationService.addNotification('sandbox.pics.upload.error.message', ENotificationCode.error);
            reject();
          },
        });
    });
  };

  private _getPics$(): Observable<FileData[]> {
    return this._serviceVisit.getPics$(this.visitParams.id).pipe(
      map(pictures =>
        pictures.map(picture => ({
          id: picture.id,
          url: picture.url,
          type: 'Image',
        }))
      )
    );
  }
}
