import { Component, Input } from '@angular/core';

import { isToday } from 'date-fns';

import { CamRoutes } from '@camelot/menu';
import { fullName } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { EOfferRequestRoute } from 'src/app/modules/offer-request/routing.module';
import { Reminder } from 'src/app/services/clientFollowUp/reminders/dto/reminder';
import { ReminderStatus } from 'src/app/services/clientFollowUp/reminders/dto/reminder-status';

@Component({
  selector: 'app-reminder-card-base',
  templateUrl: './reminder-card-base.component.html',
  styleUrls: ['./reminder-card-base.component.scss'],
})
export class ReminderCardBaseComponent extends BaseComponent {
  @Input() reminder!: Reminder;

  get title() {
    return this.reminder.client.naming ? fullName(this.reminder.client.naming) : '';
  }

  get isReminderDone() {
    return this.reminder.status === ReminderStatus.Done;
  }

  get isNotToday() {
    return !isToday(new Date(this.reminder.date));
  }

  public reminderDate(date: string | null) {
    return date?.toString() ?? '';
  }

  public cardSelected(): void {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([EOfferRequestRoute.OfferRequest, EOfferRequestRoute.DETAIL], {
        id: this.reminder.offerRequestId,
      })
    );
  }
}
