import { Component, OnInit } from '@angular/core';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';

import { PageScope } from '../../type';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './sub-list.component.html',
  styleUrls: ['./sub-list.component.scss'],
})
export class SubListPage extends BasePage implements OnInit {
  public warehouseId: number | null = null;
  public scope: PageScope = '';

  constructor() {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getPathParams<{ id: number } & { scope: PageScope }>({ id: 0, scope: '' }).subscribe(detailParams => {
        this.warehouseId = detailParams.id;
        this.scope = detailParams.scope;
        this._setPageInformation({
          level: EPageLevel.Sub,
          name: 'stock.pages.' + detailParams.scope,
        });
        this._navigationService.setPageData(this.warehouseId);
      })
    );
  }
}
