import { Injectable } from '@angular/core';

import { of } from 'rxjs';

import { CamRoutes, Menu, MenuBase, MenuIcon } from '@camelot/menu';

import { EOfferRequestRoute } from 'src/app/modules/offer-request/routing.module';
import { ETaskRoute } from 'src/app/modules/tasks/routing.module';
import { EVisitRoute } from 'src/app/modules/visits/routing.module';
import { DetailParams } from 'src/app/modules/visits/types';
import { EWorkingSiteRoute } from 'src/app/modules/working-site/routing.module';

import { Type } from '../visits/dto/type';

@Injectable({
  providedIn: 'root',
})
export class AppMenuQuickActionService {
  constructor() {}

  public getVisitDetailMenu(params: DetailParams): Menu {
    return this._sortMenu([
      new MenuIcon({
        key: 'missions',
        label: 'quick-actions.missions',
        icon: 'projects',
        order: 1,
        visible$: of(
          params.type === Type.WorkingSite ||
            params.type === Type.Sav ||
            params.type === Type.Maintenance ||
            params.type === Type.Technical
        ),
        link: CamRoutes.getAbsoluteUrl([EVisitRoute.VISIT, EVisitRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'missions' },
        }),
      }),
      new MenuIcon({
        key: 'incidents',
        label: 'quick-actions.incidents',
        icon: 'task-canceled',
        order: 1,
        visible$: of(
          params.type === Type.WorkingSite ||
            params.type === Type.Sav ||
            params.type === Type.Maintenance ||
            params.type === Type.Technical
        ),
        link: CamRoutes.getAbsoluteUrl([EVisitRoute.VISIT, EVisitRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'incidents' },
        }),
      }),
      new MenuIcon({
        key: 'pictures',
        label: 'quick-actions.pictures.title',
        icon: 'image',
        order: 2,
        link: CamRoutes.getAbsoluteUrl([EVisitRoute.VISIT, EVisitRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'pictures' },
        }),
      }),
      new MenuIcon({
        key: 'checklists',
        label: 'quick-actions.check-lists',
        icon: 'tasks-multi',
        order: 3,
        visible$: of(
          params.type === Type.WorkingSite ||
            params.type === Type.Advisor ||
            params.type === Type.Technical ||
            params.type === Type.Sav ||
            params.type === Type.Maintenance
        ),
        link: CamRoutes.getAbsoluteUrl([EVisitRoute.VISIT, EVisitRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'checklists' },
        }),
      }),
      new MenuIcon({
        key: 'comments',
        label: 'quick-actions.comments',
        icon: 'note',
        order: 4,
        link: CamRoutes.getAbsoluteUrl([EVisitRoute.VISIT, EVisitRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'comments' },
        }),
      }),
      new MenuIcon({
        key: 'files',
        label: 'quick-actions.files',
        icon: 'folders',
        order: 5,
        link: CamRoutes.getAbsoluteUrl([EVisitRoute.VISIT, EVisitRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'files' },
        }),
      }),
    ]);
  }

  public getOfferRequestDetailMenu(params: { id: number }): Menu {
    return this._sortMenu([
      new MenuIcon({
        key: 'visits',
        label: 'quick-actions.visits',
        icon: 'compasses-2',
        order: 1,
        link: CamRoutes.getAbsoluteUrl([EOfferRequestRoute.OfferRequest, EOfferRequestRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'visits' },
        }),
      }),
      new MenuIcon({
        key: 'pictures',
        label: 'quick-actions.pictures.title',
        icon: 'image',
        order: 2,
        link: CamRoutes.getAbsoluteUrl([EOfferRequestRoute.OfferRequest, EOfferRequestRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'pictures' },
        }),
      }),
      new MenuIcon({
        key: 'comments',
        label: 'quick-actions.comments',
        icon: 'note',
        order: 3,
        link: CamRoutes.getAbsoluteUrl([EOfferRequestRoute.OfferRequest, EOfferRequestRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'comments' },
        }),
      }),
      new MenuIcon({
        key: 'files',
        label: 'quick-actions.files',
        icon: 'folders',
        order: 4,
        link: CamRoutes.getAbsoluteUrl([EOfferRequestRoute.OfferRequest, EOfferRequestRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'files' },
        }),
      }),
    ]);
  }

  public getWorkingSiteDetailMenu(params: { id: number }): Menu {
    return this._sortMenu([
      new MenuIcon({
        key: 'visits',
        label: 'quick-actions.visits',
        icon: 'compasses-2',
        order: 1,
        link: CamRoutes.getAbsoluteUrl([EWorkingSiteRoute.WORKINGSITE, EWorkingSiteRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'visits' },
        }),
      }),
      new MenuIcon({
        key: 'pictures',
        label: 'quick-actions.pictures.title',
        icon: 'image',
        order: 2,
        link: CamRoutes.getAbsoluteUrl([EWorkingSiteRoute.WORKINGSITE, EWorkingSiteRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'pictures' },
        }),
      }),
      new MenuIcon({
        key: 'comments',
        label: 'quick-actions.comments',
        icon: 'note',
        order: 3,
        link: CamRoutes.getAbsoluteUrl([EWorkingSiteRoute.WORKINGSITE, EWorkingSiteRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'comments' },
        }),
      }),
      new MenuIcon({
        key: 'files',
        label: 'quick-actions.files',
        icon: 'folders',
        order: 4,
        link: CamRoutes.getAbsoluteUrl([EWorkingSiteRoute.WORKINGSITE, EWorkingSiteRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'files' },
        }),
      }),
    ]);
  }

  public getTaskDetailMenu(params: { id: number }): Menu {
    return this._sortMenu([
      new MenuIcon({
        key: 'subtasks',
        label: 'quick-actions.subtask',
        icon: 'tasks',
        order: 1,
        link: CamRoutes.getAbsoluteUrl([ETaskRoute.TASK, ETaskRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'subtasks' },
        }),
      }),
      new MenuIcon({
        key: 'pictures',
        label: 'quick-actions.pictures.title',
        icon: 'image',
        order: 2,
        link: CamRoutes.getAbsoluteUrl([ETaskRoute.TASK, ETaskRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'pictures' },
        }),
      }),
      new MenuIcon({
        key: 'comments',
        label: 'quick-actions.comments',
        icon: 'note',
        order: 3,
        link: CamRoutes.getAbsoluteUrl([ETaskRoute.TASK, ETaskRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'comments' },
        }),
      }),
      new MenuIcon({
        key: 'files',
        label: 'quick-actions.files',
        icon: 'folders',
        order: 4,
        link: CamRoutes.getAbsoluteUrl([ETaskRoute.TASK, ETaskRoute.SUBDETAIL], {
          ...params,
          ...{ scope: 'files' },
        }),
      }),
    ]);
  }

  public getVanDetailMenu() {
    return this._sortMenu([
      new MenuIcon({
        key: 'my-stock',
        label: 'stock.van.detail.swiper.my-stock',
        icon: 'database',
        order: 1,
      }),
      new MenuIcon({
        key: 'exit-voucher',
        label: 'stock.van.detail.swiper.exit-voucher',
        icon: 'upload',
        order: 2,
      }),
      new MenuIcon({
        key: 'return-voucher',
        label: 'stock.van.detail.swiper.return-voucher',
        icon: 'download',
        disabled: true,
        order: 3,
      }),
    ]);
  }

  private _sortMenu(menu: MenuBase[]) {
    return new Menu({
      elements: menu.filter(x => x.visible$).sort((a, b) => a.order - b.order),
      direction: 'horizontal',
    });
  }
}
