import { Component } from '@angular/core';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { PageScope } from '../../types';
import { EPageLevel } from 'src/app/core/navigation/navigation.service';

@Component({
  selector: 'app-working-site-sub-detail',
  templateUrl: './working-site-sub-detail.component.html',
  styleUrls: ['./working-site-sub-detail.component.scss']
})
export class WorkingSiteSubDetailPage extends BasePage {
  public id: number = 0;

  public scope: PageScope = '';

  constructor() {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getPathParams<{ id: number; scope: PageScope }>({ id: 0, scope: '' }).subscribe(({ id, scope }) => {
        this._setPageInformation({
          level: EPageLevel.Sub,
          name: 'offer-request.pages.sub.' + scope,
        });

        this.id = id;
        this.scope = scope;
      })
    );
  }
}