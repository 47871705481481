import { Component, OnInit } from '@angular/core';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { VisitDetail } from 'src/app/services/visits/dto/visit-detail';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './visits-agenda.component.html',
  styleUrls: ['./visits-agenda.component.scss'],
})
export class VisitsAgendaPage extends BasePage implements OnInit {
  public viewDate: Date = new Date();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getQueryParams({ date: '' }).subscribe(({ date }) => {
        this._setPageInformation({
          level: EPageLevel.Main,
          name: 'visits.pages.planned.agenda.title',
        });

        if (date) this.viewDate = new Date(date);
      })
    );
  }

  public formatEvents(events: VisitDetail[]) {
    return events.map(visit => ({
      title: visit.id.toString(),
      start: new Date(visit.startDate),
      end: new Date(visit.endDate),
      meta: visit,
    }));
  }
}
