import { Component, Input } from '@angular/core';

import { isToday } from 'date-fns';

import { CamRoutes } from '@camelot/menu';
import { fullName } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { getProspectionStatusType } from 'src/app/modules/offer-request/helpers';
import { EOfferRequestRoute } from 'src/app/modules/offer-request/routing.module';
import { Prospection } from 'src/app/services/clientFollowUp/prospections/dto/prospection';
import { ProspectionStatus } from 'src/app/services/clientFollowUp/prospections/dto/prospection-status';

@Component({
  selector: 'app-prospection-card',
  templateUrl: './prospection-card.component.html',
  styleUrls: ['./prospection-card.component.scss'],
})
export class ProspectionCardComponent extends BaseComponent {
  @Input() prospection!: Prospection;

  get title() {
    return this.prospection.client?.naming ? fullName(this.prospection.client?.naming) : '';
  }

  get isProspectionDone() {
    return this.prospection.status === ProspectionStatus.Done;
  }

  get isNotToday() {
    return !isToday(new Date(this.prospection.date));
  }

  public prospectionDate(date: string | null) {
    return date?.toString() ?? '';
  }

  public cardSelected(): void {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([EOfferRequestRoute.OfferRequest, EOfferRequestRoute.DETAIL], {
        id: this.prospection.offerRequestId,
      })
    );
  }

  public getStatusTranslation(prospection: Prospection) {
    return 'client-follow-up.prospection.status.' + ProspectionStatus[prospection.status].toLocaleLowerCase();
  }
  public getStatusType(prospection: Prospection) {
    return getProspectionStatusType(prospection);
  }
}
