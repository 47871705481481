import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';
import { AuthGuard, FeatureGuard } from '@camelot/user';

import { VansListPage } from './pages/vans-list/vans-list-page.component';
import { SubListPage } from './pages/warehouse-detail-page/sub-list/sub-list.component';
import { WarehouseDetailPage } from './pages/warehouse-detail-page/warehouse-detail-page.component';

export enum EStockRoute {
  STOCK = 'stock',
  VAN = 'van',
  VAN_DETAIL = 'van_detail',
  SUBDETAIL = 'subdetail',
}

export class AppStocksRoutingModule {
  public static loadRoutes() {
    CamRoutes.addRoute({
      key: EStockRoute.STOCK,
      url: 'stock',
      children: [
        {
          key: EStockRoute.VAN,
          url: 'van',
        },
        {
          key: EStockRoute.VAN_DETAIL,
          url: 'van/:id',
        },
        {
          key: EStockRoute.SUBDETAIL,
          url: 'van/:id/:scope',
        },
      ],
    });
  }

  public static getRouting(): Routes {
    return [
      // only for redirection
      {
        path: '',
        redirectTo: CamRoutes.getAbsoluteUrl([EStockRoute.STOCK, EStockRoute.VAN]),
        pathMatch: 'full',
      },
      {
        path: '',
        children: [
          {
            path: CamRoutes.getUrl([EStockRoute.STOCK, EStockRoute.VAN]),
            component: VansListPage,
            canActivate: [AuthGuard, FeatureGuard],
            data: { feature: 'worker', level: 'all' },
          },
          {
            path: CamRoutes.getUrl([EStockRoute.STOCK, EStockRoute.VAN_DETAIL]),
            component: WarehouseDetailPage,
            canActivate: [AuthGuard, FeatureGuard],
            data: { feature: 'worker', level: 'all' },
          },
          {
            path: CamRoutes.getUrl([EStockRoute.STOCK, EStockRoute.SUBDETAIL]),
            component: SubListPage,
            canActivate: [AuthGuard, FeatureGuard],
            data: { feature: 'worker', level: 'all' },
          },
        ],
      },
    ];
  }
}
