import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';

import { AppChecklistViewPage } from './pages/view/checklist-view.component';

export enum ECheckListRoute {
  CHECKLIST = 'checklist',
  VIEW = 'view',
}

export class AppCheckListsRoutingModule {
  public static loadRoutes() {
    CamRoutes.addRoute({
      key: ECheckListRoute.CHECKLIST,
      url: 'checklists',
      children: [
        {
          key: ECheckListRoute.VIEW,
          url: 'view/:visitType/:visitId/:id',
        },
      ],
    });
  }

  public static getRouting(): Routes {
    return [
      {
        path: CamRoutes.getUrl([ECheckListRoute.CHECKLIST, ECheckListRoute.VIEW]),
        component: AppChecklistViewPage,
      },
    ];
  }
}
