<cam-card (click)="this.cardSelected()" [fullHeight]="true">
  <cam-card-header>
    <cam-card-tag class="tags">
      <div class="space-between">
        <div class="mr-space-xs">
          <cam-badge
            class="badge-container"
            [value]="this.reminderDate(this.reminder.date | date : 'shortDate')"
            type="info"
          ></cam-badge>
        </div>
        <div>
          <cam-badge
            [value]="this.getStatusTranslation(this.reminder)"
            [type]="this.getStatusType(this.reminder)"
          ></cam-badge>
        </div>
      </div>
    </cam-card-tag>
    <cam-card-title>{{ this.title }}</cam-card-title>
  </cam-card-header>
  <cam-card-content>
    {{ this.fullName }}
  </cam-card-content>
</cam-card>
