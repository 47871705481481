<cam-contact-information [value]="'base.card.address.title'" icon="pin">
  <cam-list-container class="content">
    <ng-container *ngFor="let item of this.addresses">
      <cam-list-element>
        <cam-list-title>
          <div>{{ item.street }}</div>
          <div>{{ item.postalCode }} {{ item.locality }}</div>
          <div>{{ item.country }}</div>
        </cam-list-title>
        <cam-list-sub-title>
          <div class="mt-space-xs flex-start">
            <span class="mr-space-xs" *ngFor="let type of item.addressType">
              <cam-badge [value]="this.getLabelForAddressesType(type)"></cam-badge>
            </span>
          </div>
        </cam-list-sub-title>
        <cam-list-tag *ngIf="this.withButton">
          <cam-button (action)="this.launchAddress(item)" [options]="{ circular: 'small' }">
            <cam-font-icon name="projects" type="sm"></cam-font-icon>
          </cam-button>
        </cam-list-tag>
      </cam-list-element>
    </ng-container>
  </cam-list-container>
</cam-contact-information>
