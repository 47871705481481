<cam-layout-page>
  <cam-layout-header>
    <cam-layout-header-default [title]="this.pageTitle"></cam-layout-header-default>
  </cam-layout-header>

  <div *ngIf="this.visitParams && this.id">
    <app-checklist-sections [id]="this.id" [visitParams]="this.visitParams"> </app-checklist-sections>
  </div>

  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
