import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CamMainRoute, CamRoutes } from '@camelot/menu';

import { HomePage } from './modules/base/home/home.component';
import { NotFoundPage } from './modules/base/not-found/not-found.component';
import { AppCheckListsRoutingModule, ECheckListRoute } from './modules/checklists/routing.module';
import { AppOfferRequestsRoutingModule, EOfferRequestRoute } from './modules/offer-request/routing.module';
import { AppRemindersRoutingModule, EReminderRoute } from './modules/reminders/routing.module';
import { AppStocksRoutingModule, EStockRoute } from './modules/stock/van/routing.module';
import { AppTasksRoutingModule, ETaskRoute } from './modules/tasks/routing.module';
import { AppTiersRoutingModule, ETierRoute } from './modules/tiers/routing.module';
import { AppVisitsRoutingModule, EVisitRoute } from './modules/visits/routing.module';
import { AppWorkingSiteRoutingModule, EWorkingSiteRoute } from './modules/working-site/routing.module';

AppWorkingSiteRoutingModule.loadRoutes();
AppTiersRoutingModule.loadRoutes();
AppVisitsRoutingModule.loadRoutes();
AppRemindersRoutingModule.loadRoutes();
AppOfferRequestsRoutingModule.loadRoutes();
AppCheckListsRoutingModule.loadRoutes();
AppTasksRoutingModule.loadRoutes();
AppStocksRoutingModule.loadRoutes();

const routes: Routes = [
  {
    path: CamRoutes.getUrl([CamMainRoute.HOME]),
    component: HomePage,
  },
  {
    path: CamRoutes.getUrl([ETierRoute.TIER]),
    loadChildren: () => import('./modules/tiers/tiers.module').then(m => m.AppTiersModule),
  },
  {
    path: CamRoutes.getUrl([EVisitRoute.VISIT]),
    loadChildren: () => import('./modules/visits/visits.module').then(m => m.AppVisitsModule),
  },
  {
    path: CamRoutes.getUrl([EReminderRoute.REMINDER]),
    loadChildren: () => import('./modules/reminders/reminders.module').then(m => m.AppRemindersModule),
  },
  {
    path: CamRoutes.getUrl([EOfferRequestRoute.OfferRequest]),
    loadChildren: () => import('./modules/offer-request/offer-request.module').then(m => m.AppOfferRequestModule),
  },
  {
    path: CamRoutes.getUrl([EWorkingSiteRoute.WORKINGSITE]),
    loadChildren: () => import('./modules/working-site/working-site.module').then(m => m.AppWorkingSiteModule),
  },
  {
    path: CamRoutes.getUrl([ECheckListRoute.CHECKLIST]),
    loadChildren: () => import('./modules/checklists/checklists.module').then(m => m.AppChecklistsModule),
  },
  {
    path: CamRoutes.getUrl([ETaskRoute.TASK]),
    loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.AppTasksModule),
  },
  {
    path: CamRoutes.getUrl([EStockRoute.STOCK]),
    loadChildren: () => import('./modules/stock/van/van.module').then(m => m.AppVanModule),
  },
  {
    path: '**',
    component: NotFoundPage,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
