<div class="ta-c m-a">
  <app-visits-planned-by-day
    [viewDate]="this.viewDate"
    [displayTemplate]="this.listDayTemplate"
  ></app-visits-planned-by-day>
</div>

<ng-template #listDayTemplate let-events="events">
  <cam-calendar-by-day
    [viewDate]="this.viewDate"
    [events]="this.formatEvents(events ?? [])"
    [eventTemplate]="cardVisitDayTemplate"
    [hourSegmentHeight]="70"
  ></cam-calendar-by-day>
</ng-template>

<ng-template #cardVisitDayTemplate let-weekEvent="weekEvent">
  <div class="p-space-xs ta-l">
    <app-card-visit-day [fullHeight]="true" [visitByDay]="weekEvent.event.meta"></app-card-visit-day>
  </div>
</ng-template>
