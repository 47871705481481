import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

import { map, of } from 'rxjs';

import {
  InputBase,
  InputDatePicker,
  InputDropdown,
  InputPanel,
  InputRadio,
  InputTextBox,
  InputTextarea,
} from '@camelot/form-model';
import { CamIconType } from '@camelot/icons';
import { CamBaseService } from '@camelot/server';
import { convertToNumber, extractEnum, getUniqueValues, isNonNullable } from '@camelot/utils';

import { NamingBase } from '../../common/dto/namingBase';
import { PersonBase } from '../../common/dto/personBase';
import { AppUserProfileService } from '../../user-profile/user-profile.service';
import { AppUsersService } from '../../users/users.service';
import { CriticalityStatus } from '../dto/criticality-status';
import { TaskJobPost, TaskJobPut } from '../dto/post/task-job';
import { TaskJob } from '../dto/task-job';
import { TaskJobLight } from '../dto/task-job-light';
import { TaskJobStatus } from '../dto/task-job-status';
import { AppTasksService } from '../tasks.service';

enum ECreationForm {
  type = 'type',
  criticality = 'criticality',
  responsible = 'responsible',
  requester = 'requester',
  client = 'client',
  title = 'title',
  description = 'description',
  deadline = 'deadline',
  link = 'link',
  nextAction = 'nextAction',
  nextActionDate = 'nextActionDate',
  nextActionComment = 'nextActionComment',
}

export interface FilteredFields {
  status: TaskJobStatus[];
  types?: number[];
  criticality?: CriticalityStatus[];
  responsibles?: number[];
  requesters?: number[];
  clients?: number[];
  deadline?: string;
}
@Injectable({
  providedIn: 'root',
})
export class AppTasksFormService extends CamBaseService {
  constructor(
    private _tasksService: AppTasksService,
    private _usersService: AppUsersService,
    private _profileService: AppUserProfileService
  ) {
    super();

    this._tasksService.fetchTaskType$().subscribe();
    this._usersService.fetchList$().subscribe();
  }

  public getCreationForm(data?: TaskJob, clientName?: string | null, contextName?: string | null): InputBase<any>[] {
    const isRequester = data ? this._profileService.getUserProfile$.getValue()?.id === data?.requester.id : true;
    return [
      new InputPanel({
        key: 'main-panel',
        label: 'tasks.form.title',
        class: 'p-5',
        children: [
          new InputDropdown({
            key: ECreationForm.type,
            label: 'tasks.form.add.type',
            class: 'pb-2',
            value: data?.type.id.toString(),
            options: this._tasksService.getTaskTypes$.pipe(
              map(list =>
                list.map(item => ({
                  id: item.id.toString(),
                  name: item.translatedValue,
                }))
              )
            ),
            validators: [Validators.required],
            disabled: !isRequester,
          }),
          new InputDropdown({
            key: ECreationForm.criticality,
            label: 'tasks.form.add.criticality',
            class: 'pb-2',
            value: data?.criticality.toString(),
            options: of(
              extractEnum(CriticalityStatus)
                .map(item => ({
                  id: item.value.toString(),
                  name: 'tasks.card.criticality.' + item.value,
                }))
                .filter(item => item.id !== '0')
            ),
            validators: [Validators.required],
            disabled: !isRequester,
          }),
          ...this._linkContext(clientName, contextName),
          new InputDropdown({
            key: ECreationForm.responsible,
            label: 'tasks.form.add.responsible',
            class: 'pb-2',
            options: this._usersService.users$.pipe(
              map(list =>
                list.map(item => ({
                  id: item.id.toString(),
                  name: this._getUserName(item),
                }))
              )
            ),
            disabled: !isRequester,
          }),
          new InputTextBox({
            key: ECreationForm.title,
            label: 'tasks.form.add.title',
            class: 'pb-2',
            value: data?.title,
            validators: [Validators.required],
            disabled: !isRequester,
          }),
          new InputTextarea({
            key: ECreationForm.description,
            label: 'tasks.form.add.description',
            class: 'pb-2',
            value: data?.description,
          }),
          new InputDatePicker({
            key: ECreationForm.deadline,
            label: 'tasks.form.add.deadline',
            minDate: 'today',
            value: data?.deadline ?? undefined,
            disabled: !isRequester,
          }),
          ...this._nextAction(data),
        ],
      }),
    ];
  }

  public formatCreationForm(data: any, parentId: number | null): TaskJobPost {
    return {
      title: data[ECreationForm.title],
      deadline: data[ECreationForm.deadline],
      criticality: data[ECreationForm.criticality],
      description: data[ECreationForm.description],
      nextAction:
        data[ECreationForm.nextAction] === true
          ? { action: data[ECreationForm.nextActionComment], date: data[ECreationForm.nextActionDate] }
          : null,
      responsible: { id: Number(data[ECreationForm.responsible]) },
      type: { id: Number(data[ECreationForm.type]) },
      parentTaskId: parentId,
    };
  }

  public formatUpdateForm(data: any): TaskJobPut {
    return {
      description: data[ECreationForm.description],
      nextAction:
        data[ECreationForm.nextAction] === true
          ? { action: data[ECreationForm.nextActionComment], date: data[ECreationForm.nextActionDate] }
          : null,
    };
  }

  public getFilterForm(list: TaskJobLight[], current: FilteredFields): InputBase<any>[] {
    return [
      new InputPanel({
        key: 'main-panel',
        label: 'tasks.form.title',
        class: 'p-10',
        containerClass: ['highlight-title', 'with-separator'],
        children: [
          new InputDropdown({
            key: ECreationForm.type,
            label: 'tasks.form.add.type',
            class: 'pb-2',
            multiple: true,
            value: current.types,
            options: of(
              getUniqueValues(
                list.map(item => item.type),
                type => type.id
              ).map(item => ({
                id: item.id.toString(),
                name: item.translatedValue,
              }))
            ),
          }),
          new InputDropdown({
            key: ECreationForm.criticality,
            label: 'tasks.form.add.criticality',
            class: 'pb-2',
            multiple: true,
            value: current.criticality,
            options: of(
              extractEnum(CriticalityStatus)
                .filter(criticality => list.find(item => item.criticality === criticality.value))
                .map(item => ({
                  id: item.value.toString(),
                  name: 'tasks.card.criticality.' + item.value,
                }))
                .filter(item => item.id !== '0')
            ),
          }),
          new InputDropdown({
            key: ECreationForm.responsible,
            label: 'tasks.form.add.responsible',
            class: 'pb-2',
            multiple: true,
            value: current.responsibles,
            options: of(
              getUniqueValues(
                list.map(item => item.responsible),
                responsible => responsible.id
              )
                .map(item => ({
                  id: item.id.toString(),
                  name: this._getUserName(item),
                }))
                .sort((a, b) => (a.name > b.name ? 1 : -1))
            ),
          }),
          new InputDropdown({
            key: ECreationForm.requester,
            label: 'tasks.form.add.requester',
            class: 'pb-2',
            multiple: true,
            value: current.requesters,
            options: of(
              getUniqueValues(
                list.map(item => item.requester),
                requester => requester.id
              )
                .map(item => ({
                  id: item.id.toString(),
                  name: this._getUserName(item),
                }))
                .sort((a, b) => (a.name > b.name ? 1 : -1))
            ),
          }),
          new InputDropdown({
            key: ECreationForm.client,
            label: 'tasks.form.add.client',
            class: 'pb-2',
            multiple: true,
            value: current.clients,
            options: of(
              getUniqueValues(list.map(item => item.relatedClient).filter(isNonNullable), client => client.id)
                .map(item => ({
                  id: item.id.toString(),
                  name: this._getUserName(item),
                }))
                .sort((a, b) => (a.name > b.name ? 1 : -1))
            ),
          }),
          new InputDatePicker({
            key: ECreationForm.deadline,
            label: 'tasks.form.add.deadline',
            value: current.deadline,
          }),
        ],
      }),
    ];
  }

  public formatFilterForm(data: any): Omit<FilteredFields, 'status'> {
    if (!data) {
      return {};
    }
    return {
      types: convertToNumber(data[ECreationForm.type]),
      criticality: convertToNumber(data[ECreationForm.criticality]),
      responsibles: convertToNumber(data[ECreationForm.responsible]),
      requesters: convertToNumber(data[ECreationForm.requester]),
      clients: convertToNumber(data[ECreationForm.client]),
      deadline: data[ECreationForm.deadline],
    };
  }

  private _linkContext(clientName?: string | null, contextName?: string | null): InputBase<any>[] {
    const clientSelector = new InputTextBox({
      key: 'clientId',
      label: 'tasks.form.add.client',
      class: 'pb-2',
      value: clientName as string,
      visible$: of(!!clientName),
      disabled: true,
    });

    const contextTextBox = new InputTextBox({
      label: 'tasks.form.add.workingsite',
      class: 'pb-2',
      value: contextName ?? '',
      visible$: of(!!contextName),
      disabled: true,
    });

    return [clientSelector, contextTextBox];
  }

  private _nextAction(data?: TaskJob): InputBase<any>[] {
    const show = new InputRadio<boolean>({
      key: ECreationForm.nextAction,
      class: 'col-12',
      label: 'tasks.form.add.next-action',
      value: !!data?.nextAction,
      options: of([
        {
          id: true,
          name: 'radio.yes',
          icon: CamIconType.CheckLight,
        },
        {
          id: false,
          name: 'radio.no',
          icon: CamIconType.CancelLight,
        },
      ]),
    });

    return [
      show,
      new InputPanel({
        key: 'main-panel',
        visible$: show.changeValue$,
        children: [
          new InputDatePicker({
            key: ECreationForm.nextActionDate,
            label: 'tasks.form.add.next-action-date',
            minDate: 'today',
            value: data?.nextAction?.date ?? undefined,
          }),
          new InputTextBox({
            key: ECreationForm.nextActionComment,
            label: 'tasks.form.add.next-action-comment',
            class: 'pb-2',
            value: data?.nextAction?.action,
          }),
        ],
      }),
    ];
  }

  private _getUserName(user: PersonBase<NamingBase>): string {
    return `${user.naming?.firstName} ${user.naming?.name}`;
  }
}
