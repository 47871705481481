import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { CalendarView } from 'angular-calendar';
import { format } from 'date-fns';

import { BottomSheetTemplateGenericComponent, BottomSheetTemplateGenericParams, Menu } from '@camelot/menu';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { AppMenuSecondService } from 'src/app/services/menus/second.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './visits-planned.component.html',
  styleUrls: ['./visits-planned.component.scss'],
})
export class VisitsPlannedPage extends BasePage implements OnInit {
  @ViewChild('monthVisitsTemplate', { read: TemplateRef })
  monthVisitsTemplate!: TemplateRef<void>;

  public view = CalendarView.Day;
  public viewDate: Date = new Date();

  public menu = this._getMenu();

  constructor(private _menuService: AppMenuSecondService, private _bottomSheet: MatBottomSheet) {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getQueryParams({ date: '' }).subscribe(({ date }) => {
        this._setPageInformation({
          level: EPageLevel.Main,
          name: 'visits.pages.planned.title',
        });

        if (date) this.viewDateChanged(new Date(date));
      })
    );
  }

  public viewDateChanged(viewDate: Date) {
    this.viewDate = viewDate;

    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        date: format(this.viewDate, 'yyyy-MM-dd'),
      },
      queryParamsHandling: 'merge',
    });
  }

  public showMonth() {
    this.openBottom();
  }

  public openBottom() {
    this._bottomSheet.open<BottomSheetTemplateGenericComponent, BottomSheetTemplateGenericParams>(
      BottomSheetTemplateGenericComponent,
      {
        data: {
          template: this.monthVisitsTemplate,
        },
      }
    );
  }

  public closeBottom(viewDate?: Date) {
    this._bottomSheet.dismiss();
    if (viewDate) {
      this.viewDateChanged(viewDate);
    }
  }

  private _getMenu(): Menu {
    return this._menuService.getVisitMenu();
  }
}
