<cam-card [highlight]="this.highlight">
  <cam-card-header>
    <cam-card-title>
      {{ this.fullName }}
    </cam-card-title>
    <cam-card-subtitle>
      <ng-container *ngIf="this.getFunction()">
        <span class="function-container">
          {{ this.getFunction() }}
        </span>
      </ng-container>
    </cam-card-subtitle>
    <cam-card-tag>
      <div class="space-between">
        <ng-container *ngIf="this.getDepartments()">
          <cam-department-icon-list class="my-a" [departments]="getDepartments() || []"></cam-department-icon-list>
        </ng-container>
        <app-card-person-tag-civility class="civility" [person]="this.person"></app-card-person-tag-civility>
      </div>
    </cam-card-tag>
  </cam-card-header>
  <cam-card-content>
    <ng-container *ngIf="!this.summaryInformation">
      <cam-contact-information
        *ngIf="this.showPhone"
        [value]="this.getPhone()"
        [localIcon]="this.icon.Smartphone"
      ></cam-contact-information>
      <cam-contact-information [value]="this.person.email" [localIcon]="this.icon.Gmail"></cam-contact-information>
    </ng-container>
    <ng-container *ngIf="this.summaryInformation">
      <app-phones-card [phoneNumber]="this.getPhone()" [withButton]="true"></app-phones-card>
    </ng-container>
    <div class="custom-information">
      <ng-content></ng-content>
    </div>
  </cam-card-content>
  <cam-card-cta *ngIf="this.showCallButton">
    <div class="row">
      <div class="col" *ngIf="this.person.email">
        <cam-button (action)="this.sendMail()" [style]="'secondary'">
          <cam-font-icon name="send"></cam-font-icon>
        </cam-button>
      </div>
      <div class="col" *ngIf="this.showPhone && this.getPhone()">
        <cam-button (action)="this.launchCall()">
          <cam-font-icon name="phone"></cam-font-icon>
        </cam-button>
      </div>
    </div>
  </cam-card-cta>
</cam-card>
