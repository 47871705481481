import { Component, OnDestroy } from '@angular/core';

import { map, of } from 'rxjs';

import { InputDropdown } from '@camelot/form-model';
import { CamTranslationService } from '@camelot/translation';
import { Permissions } from '@camelot/user';

import { BaseComponent } from '../../../core/abstract/baseComponent';

@Component({
  selector: 'app-menu-user',
  templateUrl: './menu-user.component.html',
  styleUrls: ['./menu-user.component.scss'],
})
export class MenuUserComponent extends BaseComponent implements OnDestroy {
  get roles() {
    return Permissions.roles;
  }

  get name$() {
    return this._userProfileService.getUserProfile$.pipe(map(user => user?.firstName ?? ''));
  }
  get isAuthenticated$() {
    return this._userService.isAuthenticated$;
  }
  get getFirstLetter() {
    return this._userService.firstLetter;
  }

  public language = new InputDropdown<string>({
    label: '',
    options: of([
      { id: 'fr', name: 'Français' },
      { id: 'nl', name: 'Nederlands' },
      { id: 'en', name: 'English' },
      { id: 'es', name: 'Español' },
    ]),
  });

  constructor(public translateService: CamTranslationService) {
    super();

    this.language.value = this.translateService.getLanguage();
    this.language.createFormControl();

    this._registerSubscription(this.language.changeValue$.subscribe(value => this.translateService.use(value)));
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.language.destroy();
  }

  public login() {
    this._userService.login();
  }

  public logout() {
    this._userService.logout();
  }
}
