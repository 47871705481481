<cam-layout-page>
  <cam-layout-header>
    <cam-layout-header-default [title]="this.pageTitle"></cam-layout-header-default>
  </cam-layout-header>
  <cam-layout-content>
    <cam-guard feature="person" level="read">
      <div *ngIf="this.personId">
        <app-person-detail [id]="this.personId"></app-person-detail>
        <div class="person-menu">
          <cam-context-menu [menu]="this.getPersonMenu()"></cam-context-menu>
        </div>
      </div>
    </cam-guard>
  </cam-layout-content>
  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
