<cam-layout-page>
  <cam-layout-header>
    <cam-layout-header-default></cam-layout-header-default>
  </cam-layout-header>
  <cam-layout-content>
    <cam-guard feature="person" level="read">
      <div *ngIf="this.userId">
        <app-user-detail [id]="this.userId"></app-user-detail>
      </div>
    </cam-guard>
  </cam-layout-content>
  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
