import { Logger } from '@camelot/server';
import { merge } from '@camelot/utils';

import { baseEnvironment } from './base-environment';

export const environment = merge()<typeof baseEnvironment>(baseEnvironment, {
  SERVER_URL: 'https://arthur-dev.api.merlinsoftware.be/api/tristan', // 'http://192.168.111.72:5000/api/tristan',
  GTM: 'GTM-PP2JMXX',

  DEBUG: true,
  LOCAL: false,

  PWAACTIVE: false,
  SUPPORTURL:
    'https://teams.microsoft.com/l/channel/19%3aef4a25e4fae142409bf410640695d25b%40thread.tacv2/App%2520-%2520Tristan?groupId=370f2dec-aa46-4043-b8bd-9a22316bd126&tenantId=39ab5803-ee73-4f37-9987-68bb3c87ed15',
  WORKER_SUPPORTURL:
    'https://teams.microsoft.com/l/channel/19%3a3879b2a67c50406e824e1fdcea51d9ef%40thread.tacv2/App%2520-%2520Tristan%2520-%2520Ouvriers?groupId=370f2dec-aa46-4043-b8bd-9a22316bd126&tenantId=39ab5803-ee73-4f37-9987-68bb3c87ed15',

  INSTALLATION_URL: {
    IOS: 'https://rgroupstorage.blob.core.windows.net/tristansetup/download-app-iphone.pdf',
    ANDROID: 'https://rgroupstorage.blob.core.windows.net/tristansetup/download-app-android.pdf',
  },
  AUTH0: {
    domain: 'merlinsoftware-dev.eu.auth0.com',
    clientId: '2To3WKpfmlQB8Di5IjViNSRS9j3Yzw4a',
    authorizationParams: {
      audience: ['https://arthur-dev.api.merlinsoftware.be'], //, 'https://api-dev.merlinsoftware.be'
      organization: 'org_NzYFcHAY7KCJ9YXa',
    },
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://arthur-dev.api.merlinsoftware.be/api/tristan/*', // 'http://192.168.111.72:5000/api/tristan/*',
        },
        {
          uri: 'https://api-dev.merlinsoftware.be/*',
        },
      ],
    },
  },
});

Logger.LogInfo('environment', environment);
