<cam-layout-page>
  <cam-layout-header>
    <cam-layout-header-default [title]="this.pageTitle"></cam-layout-header-default>
  </cam-layout-header>
  <cam-layout-content>
    <cam-guard feature="person" level="read">
      <app-warehouse-detail [id]="this.id"></app-warehouse-detail>
      <div class="menu">
        <cam-context-menu [menu]="this.getWarehouseMenu()"></cam-context-menu>
      </div>
    </cam-guard>
  </cam-layout-content>
  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
