import { Component } from '@angular/core';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './task-requester.component.html',
  styleUrls: ['./task-requester.component.scss'],
})
export class TaskRequesterPage extends BasePage {
  constructor() {
    super();

    this._setPageInformation({
      level: EPageLevel.Main,
      name: '',
    });
  }
}
