import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { distinctUntilChanged, map } from 'rxjs/operators';

import { compareDesc, isSameDay } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

import { ActiveFilterTag } from '@camelot/core';
import { InputBase } from '@camelot/form-model';
import {
  BottomSheetData,
  BottomSheetTemplateBasicComponent,
  BottomSheetTemplateBasicParams,
  BottomSheetTemplateGenericComponent,
  BottomSheetTemplateGenericParams,
} from '@camelot/menu';
import { ActionButtonData } from '@camelot/ui';
import { extractEnum, fullName, getUniqueValues, isNonNullable } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { TaskJobLight } from 'src/app/services/tasks/dto/task-job-light';
import { TaskJobStatus } from 'src/app/services/tasks/dto/task-job-status';
import { AppTasksFormService, FilteredFields } from 'src/app/services/tasks/form/tasks-form.service';
import { AppTasksService, TaskType } from 'src/app/services/tasks/tasks.service';

import { ContextForTaskService } from '../../pages/context-for-task-service';
import { statusTraduction } from '../../task-helpers';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class ListComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  type!: TaskType;

  @Output()
  askChanged = new EventEmitter<void>();

  @ViewChild('addTasksTemplate', { read: TemplateRef })
  addTasksTemplate!: TemplateRef<void>;

  public activeFilters: FilteredFields = { status: [TaskJobStatus.Open] };
  public form: InputBase<any>[] = [];

  get formattedActiveFilters(): ActiveFilterTag[] {
    const keys = <Array<keyof FilteredFields>>Object.keys(this.activeFilters);
    const data = keys.reduce<ActiveFilterTag[]>((acc, key) => {
      const tags = this._getTags(key);

      return acc.concat(tags);
    }, []);

    return data;
  }
  get addActions(): ActionButtonData[] {
    return [
      {
        label: 'Add',
        icon: 'add',
        callback: () => this._add(),
      },
    ];
  }

  get list() {
    return this._tasksService.getTasks(this.type);
  }
  get data$() {
    return this._tasksService.getTasks$(this.type).pipe(distinctUntilChanged());
  }

  get filteredData$() {
    return this.data$.pipe(
      distinctUntilChanged(),
      map(task => task.filter(task => this._allowTask(task))),
      map(tasks =>
        tasks.sort((a, b) => {
          if (a.criticality === b.criticality) {
            return compareDesc(new Date(a.deadline || 0), new Date(b.deadline || 0));
          }
          return a.criticality < b.criticality ? -1 : 1;
        })
      )
    );
  }

  private _statusMenu$ = new BehaviorSubject<BottomSheetData[]>(this._generateStatusMenu());

  constructor(
    private _bottomSheet: MatBottomSheet,
    private _tasksService: AppTasksService,
    private _tasksFormService: AppTasksFormService,
    private _contextForTaskService: ContextForTaskService
  ) {
    super();
  }

  ngOnInit() {
    this.setFilters(null);
  }

  public ngAfterViewInit() {
    if (this._contextForTaskService.context.id) {
      this._add();
    }
  }

  /** Filters */
  public setFilters(data: any) {
    this.activeFilters = { ...this.activeFilters, ...this._tasksFormService.formatFilterForm(data) };

    if (!this.activeFilters.status || this.activeFilters.status.length === 0) {
      this.activeFilters.status = [TaskJobStatus.Open];
    }
    this._setForm();
    this._fetch();
  }
  public removedFilter(filter: ActiveFilterTag) {
    const [key, index] = filter.id.split('-');

    let values = this.activeFilters[key as keyof FilteredFields];
    if (!values || !Array.isArray(values)) {
      return;
    }
    values = values.splice(Number(index), 1);

    this._fetch();
  }

  /** Status */
  public getActiveStatusFilter() {
    const status = this.activeFilters['status'];
    const statusValues = extractEnum(TaskJobStatus);

    return (
      status?.map((value, index) => ({
        id: 'status-' + index,
        name:
          'tasks.filters.options.status.' + statusValues.find(item => item.value === value)?.name.toLocaleLowerCase() ??
          '',
      })) ?? []
    );
  }
  public openBottomStatus() {
    this._registerSubscription(
      this._bottomSheet
        .open<BottomSheetTemplateBasicComponent, BottomSheetTemplateBasicParams>(BottomSheetTemplateBasicComponent, {
          data: {
            orientation: 'horizontal',
            menu$: this._statusMenu$,
          },
        })
        .afterDismissed()
        .subscribe(() => this._fetch())
    );
  }

  public closeTaskBottom() {
    this._bottomSheet.dismiss();
    this.askChanged.emit();
  }

  private _getTags(key: keyof FilteredFields): ActiveFilterTag[] {
    switch (key) {
      case 'types':
        const types = this.activeFilters[key];
        const typeValues = getUniqueValues(
          this.list.map(item => item.type),
          type => type.id
        ).map(item => ({
          id: item.id,
          name: item.translatedValue,
        }));
        return (
          types?.map((value, index) => ({
            id: 'types-' + index,
            name: typeValues.find(item => item.id === value)?.name ?? '',
          })) ?? []
        );
      case 'criticality':
        const criticalities = this.activeFilters[key];

        return (
          criticalities?.map((value, index) => ({
            id: 'criticality-' + index,
            name: 'tasks.card.criticality.' + value,
          })) ?? []
        );
      case 'responsibles':
        const responsibles = this.activeFilters[key];
        const responsibleValues = getUniqueValues(
          this.list.map(item => item.responsible),
          responsible => responsible.id
        ).map(item => ({
          id: item.id,
          name: this._getUserName(item.naming),
        }));
        return (
          responsibles?.map((value, index) => ({
            id: 'responsibles-' + index,
            name: responsibleValues.find(item => item.id === value)?.name ?? '',
          })) ?? []
        );
      case 'requesters':
        const requesters = this.activeFilters[key];
        const requesterValues = getUniqueValues(
          this.list.map(item => item.requester),
          requester => requester.id
        ).map(item => ({
          id: item.id,
          name: this._getUserName(item.naming),
        }));
        return (
          requesters?.map((value, index) => ({
            id: 'requesters-' + index,
            name: requesterValues.find(item => item.id === value)?.name ?? '',
          })) ?? []
        );
      case 'clients':
        const clients = this.activeFilters[key];
        const clientValues = getUniqueValues(
          this.list.map(item => item.relatedClient).filter(isNonNullable),
          client => client.id
        ).map(item => ({
          id: item.id,
          name: this._getUserName(item.naming),
        }));
        return (
          clients?.map((value, index) => ({
            id: 'clients-' + index,
            name: clientValues.find(item => item.id === value)?.name ?? '',
          })) ?? []
        );
      default:
        return [];
    }
  }

  private _setForm() {
    this.form = this._tasksFormService.getFilterForm(this.list, this.activeFilters);
  }

  private _fetch() {
    const status = this.activeFilters.status;

    this.requestState.asked();
    this._tasksService.fetchTasks(this.type, status).subscribe({
      complete: () => {
        this.requestState.completed();
        this._setForm();
      },
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }

  private _add() {
    this._registerSubscription(
      this._bottomSheet
        .open<BottomSheetTemplateGenericComponent, BottomSheetTemplateGenericParams>(
          BottomSheetTemplateGenericComponent,
          {
            data: {
              template: this.addTasksTemplate,
            },
          }
        )
        .afterDismissed()
        .subscribe(() => this._contextForTaskService.resetContext())
    );
  }

  private _generateStatusMenu() {
    return extractEnum(TaskJobStatus, true).map(status => ({
      label: statusTraduction(status.value),
      icon: this.activeFilters.status.find(value => value === status.value) ? 'check-line' : 'add',
      action: () => this._toggleStatus(status.value),
    }));
  }
  private _toggleStatus(selectedStatus: TaskJobStatus) {
    const status = this.activeFilters.status.find(status => status === selectedStatus);

    if (!status) {
      this.activeFilters.status?.push(selectedStatus);
    } else {
      if (this.activeFilters.status.length === 1) {
        return;
      }
      this.activeFilters.status = this.activeFilters.status.filter(status => status !== selectedStatus);
    }

    this._statusMenu$.next(this._generateStatusMenu());
  }
  private _allowTask(task: TaskJobLight) {
    if (this.activeFilters.types && this.activeFilters.types.length > 0) {
      if (!this.activeFilters.types.find(itemId => itemId === task.type.id)) {
        return false;
      }
    }
    if (this.activeFilters.criticality && this.activeFilters.criticality.length > 0) {
      if (!this.activeFilters.criticality.find(itemId => itemId === task.criticality)) {
        return false;
      }
    }
    if (this.activeFilters.responsibles && this.activeFilters.responsibles.length > 0) {
      if (!this.activeFilters.responsibles.find(itemId => itemId === task.responsible.id)) {
        return false;
      }
    }
    if (this.activeFilters.requesters && this.activeFilters.requesters.length > 0) {
      if (!this.activeFilters.requesters.find(itemId => itemId === task.requester.id)) {
        return false;
      }
    }
    if (this.activeFilters.clients && this.activeFilters.clients.length > 0) {
      if (!this.activeFilters.clients.find(itemId => itemId === task.relatedClient?.id)) {
        return false;
      }
    }
    if (this.activeFilters.deadline) {
      if (!task.deadline || !isSameDay(new Date(this.activeFilters.deadline), new Date(task.deadline))) {
        return false;
      }
    }
    return true;
  }

  private _getUserName(naming: { firstName: string | null; name: string } | null) {
    if (!naming) {
      return '';
    }
    return fullName(naming);
  }
}
