import { Injectable } from '@angular/core';

export type Context = 'workingSite' | 'offerRequest';

@Injectable({
  providedIn: 'root',
})
export class ContextForTaskService {
  public context: { id: number | null; context: Context | null } = { id: null, context: null };

  constructor() {}

  setContext(id: number | null, context: Context) {
    this.context.id = id;
    this.context.context = context;
  }

  resetContext() {
    this.context.id = null;
    this.context.context = null;
  }
}
