import { Component, OnInit } from '@angular/core';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { DetailParams } from 'src/app/modules/visits/types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './checklist-view.component.html',
  styleUrls: ['./checklist-view.component.scss'],
})
export class AppChecklistViewPage extends BasePage implements OnInit {
  public visitParams: DetailParams | null = null;
  public id: number | null = null;

  constructor() {
    super();

    this._setPageInformation({
      level: EPageLevel.Sub,
      name: 'checklists.pages.view.back-title',
    });
  }
  ngOnInit(): void {
    this._registerSubscription(
      this._getPathParams({ id: 0, visitId: 0, visitType: 0 }).subscribe(({ id, visitId, visitType }) => {
        if (id && visitId && visitType) {
          this.id = Number(id);
          this.visitParams = { id: visitId, type: visitType };
        } else {
          this.id = null;
          this.visitParams = null;
        }
      })
    );
  }
}
