import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';
import { AuthGuard, FeatureGuard } from '@camelot/user';

import { SubListPage } from './pages/detail/sub-list/sub-list.component';
import { TiersDetailPage } from './pages/detail/tiers-detail.component';
import { UserPage } from './pages/detail/user/user.component';
import { TiersExternalPage } from './pages/list-base/external/tiers-external.component';
import { TiersInternalPage } from './pages/list-base/internal/tiers-internal.component';

export enum ETierRoute {
  TIER = 'tier',
  LIST = 'list',
  EXTERNAL = 'external',
  INTERNAL = 'internal',
  DETAIL = 'detail',
  SUBDETAIL = 'subdetail',
}

export class AppTiersRoutingModule {
  public static loadRoutes() {
    CamRoutes.addRoute({
      key: ETierRoute.TIER,
      url: 'tiers',
      children: [
        {
          key: ETierRoute.EXTERNAL,
          url: 'external',
          children: [
            {
              key: ETierRoute.LIST,
              url: 'list',
            },
            {
              key: ETierRoute.DETAIL,
              url: 'detail/:id',
            },
            {
              key: ETierRoute.SUBDETAIL,
              url: 'detail/:id/:scope',
            },
          ],
        },
        {
          key: ETierRoute.INTERNAL,
          url: 'internal',
          children: [
            {
              key: ETierRoute.LIST,
              url: 'list',
            },
            {
              key: ETierRoute.DETAIL,
              url: 'detail/:id',
            },
          ],
        },
      ],
    });
  }

  public static getRouting(): Routes {
    return [
      // only for redirection
      {
        path: '',
        redirectTo: CamRoutes.getAbsoluteUrl([ETierRoute.TIER, ETierRoute.EXTERNAL, ETierRoute.LIST]),
        pathMatch: 'full',
      },
      {
        path: CamRoutes.getUrl([ETierRoute.TIER, ETierRoute.EXTERNAL]),
        canActivate: [AuthGuard, FeatureGuard],
        data: { feature: 'person', level: 'read' },
        children: [
          {
            path: CamRoutes.getUrl([ETierRoute.TIER, ETierRoute.EXTERNAL, ETierRoute.LIST]),
            component: TiersExternalPage,
          },
          {
            path: CamRoutes.getUrl([ETierRoute.TIER, ETierRoute.EXTERNAL, ETierRoute.DETAIL]),
            component: TiersDetailPage,
          },
          {
            path: CamRoutes.getUrl([ETierRoute.TIER, ETierRoute.EXTERNAL, ETierRoute.SUBDETAIL]),
            component: SubListPage,
          },
        ],
      },
      {
        path: CamRoutes.getUrl([ETierRoute.TIER, ETierRoute.INTERNAL]),
        canActivate: [AuthGuard, FeatureGuard],
        data: { feature: 'arthur-user', level: 'read' },
        children: [
          {
            path: CamRoutes.getUrl([ETierRoute.TIER, ETierRoute.INTERNAL, ETierRoute.LIST]),
            component: TiersInternalPage,
          },
          {
            path: CamRoutes.getUrl([ETierRoute.TIER, ETierRoute.INTERNAL, ETierRoute.DETAIL]),
            component: UserPage,
          },
        ],
      },
    ];
  }
}
