<cam-card (click)="this.cardSelected()" [fullHeight]="true">
  <cam-card-header>
    <cam-card-tag class="tags">
      <cam-badge
        class="badge-container"
        *ngIf="this.isNotToday"
        [value]="this.reminderDate(this.reminder.date | date : 'shortDate')"
        type="info"
      ></cam-badge>

      <cam-material-icon *ngIf="this.isReminderDone" class="status-icon"> check_circle </cam-material-icon>
    </cam-card-tag>

    <cam-card-title>{{ this.title }}</cam-card-title>
  </cam-card-header>
  <cam-card-content>
    <div class="d-flex flex-row departments">
      <cam-department-icon-list [departments]="[this.reminder.priceOfferLight.department]"></cam-department-icon-list>
      <cam-department-professions
        [professions]="this.reminder.priceOfferLight.professions"
      ></cam-department-professions>
    </div>
  </cam-card-content>
  <cam-card-cta>
    <div class="mt-space-2 date-list">
      <app-price-offer-date-list [priceOffer]="this.reminder.priceOfferLight"> </app-price-offer-date-list>
    </div>
  </cam-card-cta>
</cam-card>
