import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CamIconsModule } from '@camelot/icons';
import { CamLayoutModule, CamUiModule } from '@camelot/ui';

import { LayoutContentComponent } from './layout-content/layout-content.component';
import { LayoutFirstLevelComponent } from './layout-first-level/layout-first-level.component';
import { LayoutHeaderComponent } from './layout-header/layout-header.component';
import { LayoutNavComponent } from './layout-nav/layout-nav.component';
import { LayoutTitleComponent } from './layout-title/layout-title.component';

@NgModule({
  declarations: [
    LayoutFirstLevelComponent,
    LayoutContentComponent,
    LayoutHeaderComponent,
    LayoutNavComponent,
    LayoutTitleComponent,
  ],
  imports: [CamIconsModule, CamLayoutModule, CamUiModule, CommonModule],
  exports: [
    LayoutFirstLevelComponent,
    LayoutContentComponent,
    LayoutHeaderComponent,
    LayoutNavComponent,
    LayoutTitleComponent,
  ],
})
export class AppLayoutModule {}
