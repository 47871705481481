<app-visits-planned-by-day
  [viewDate]="this.viewDate"
  [displayTemplate]="this.listDayTemplate"
></app-visits-planned-by-day>

<ng-template #listDayTemplate let-events="events">
  <cam-empty [isEmpty]="!events || events.length === 0">
    <div class="row">
      <div *ngFor="let event of events || []" class="col-12 col-md-6 col-lg-4 mb-xxs">
        <app-card-visit-day [visitByDay]="event"></app-card-visit-day>
      </div>
    </div>
  </cam-empty>
</ng-template>
