<app-layout-first-level>
  <app-layout-header>
    <app-list-header></app-list-header>
  </app-layout-header>
  <app-layout-content>
    <app-client-follow-up-list [type]="'prospections'"></app-client-follow-up-list>
  </app-layout-content>
  <app-layout-nav>
    <app-menu-main></app-menu-main>
  </app-layout-nav>
</app-layout-first-level>
