<cam-layout-page>
  <cam-layout-header>
    <app-list-header></app-list-header>
  </cam-layout-header>
  <cam-layout-content>
    <app-client-follow-up-list [type]="'workingSiteReminders'"></app-client-follow-up-list>
  </cam-layout-content>
  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
