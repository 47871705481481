// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const baseEnvironment = {
  production: false,
  version: require('../../package.json').version,

  TENANT_NAME: 'default',
  DEBUG: false,
  DEBUG_LEVEL: 0,

  LOCAL: false,

  PENDINGREQUESTAPI: 5,
  SERVER_TYPE: 'API',
  APIEXT: '',
  SERVER_URL: '',
  SUPPORTURL: '',
  WORKER_SUPPORTURL: '',
  GTM: '',

  GRAPHQL_SERVER_CONFIG: {
    url: 'https://api-graal-dev.azure-api.net/',
  },

  APP_NAME: 'Tristan dev',
  MAIN_COLOR: 'white',
  PWAACTIVE: true,

  INSTALLATION_URL: {
    IOS: '',
    ANDROID: '',
  },
  AUTH0: {
    domain: '',
    clientId: '',
    cacheLocation: 'localstorage',
    // Request this audience at user authentication time
    authorizationParams: {
      audience: '',
      redirect_uri: window.location.origin,
    },

    // Specify configuration for the interceptor
    httpInterceptor: {
      allowedList: [
        {
          uri: '',
        },
      ],
    },
  },

  TRANSLATION: {
    DEFAULT: 'fr',
    SUPPORTEDLANGUAGES: ['fr', 'en', 'nl', 'es'],
  },

  components: {
    comments: {
      delayBetween: 1 * 60, // 1h in minutes
    },
  },
};
