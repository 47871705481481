<cam-layout-page>
  <cam-layout-header>
    <app-list-header></app-list-header>
  </cam-layout-header>
  <cam-layout-content>
    <cam-guard feature="person" level="read">
      <app-user-list [searchTerm]="this.searchTerm" (chosenUser)="this.goToDetail($event)"></app-user-list>
    </cam-guard>
  </cam-layout-content>
  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
