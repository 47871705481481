import { Injectable } from '@angular/core';

import { filter, map, switchMap, tap } from 'rxjs/operators';

import { compareAsc } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

import { MappingApiType, Request } from '@camelot/server';
import { TranslatedEnumeration } from '@camelot/services';

import { BaseService } from 'src/app/core/server/baseService';

import { sortByTranslatedValue } from '../translated-enumeration/translated-enumeration-helpers';
import { TaskJobPost, TaskJobPut } from './dto/post/task-job';
import { TaskJob } from './dto/task-job';
import { TaskJobLight } from './dto/task-job-light';
import { TaskJobStatus } from './dto/task-job-status';

export type TaskType = 'responsible' | 'requester';

const apiRoutes: MappingApiType = {
  GetResponsibleTasks: {
    type: 'GET',
    url: '{ApiUrl}/tasks/responsible?statusflag={statusFlag}',
  },
  GetRequesterTasks: {
    type: 'GET',
    url: '{ApiUrl}/tasks/requester?statusflag={statusFlag}',
  },
  GetTask: {
    type: 'GET',
    url: '{ApiUrl}/tasks/{id}',
  },
  PostTask: {
    type: 'POST',
    url: '{ApiUrl}/tasks',
  },
  PutTask: {
    type: 'PUT',
    url: '{ApiUrl}/tasks/{id}',
  },
  DeleteTask: {
    type: 'DELETE',
    url: '{ApiUrl}/tasks/{id}',
  },
  PostSubTask: {
    type: 'POST',
    url: '{ApiUrl}/tasks/{id}/subtasks',
  },
  GetSubTask: {
    type: 'GET',
    url: '{ApiUrl}/tasks/{id}/subtasks',
  },
  GetTaskTypes: {
    type: 'GET',
    url: '{ApiUrl}/tasktypes',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AppTasksService extends BaseService {
  public getTaskTypes$ = new BehaviorSubject<TranslatedEnumeration[]>([]);

  private _getTasks$ = new BehaviorSubject<{
    [index: string]: TaskJobLight[];
  }>({});

  private _getTask$ = new BehaviorSubject<{
    [index: number]: TaskJob;
  }>({});

  private _getSubTasks$ = new BehaviorSubject<{
    [index: number]: TaskJobLight[];
  }>({});

  constructor() {
    super(apiRoutes);
  }

  public getTasks(type: TaskType) {
    return this._getTasks$.getValue()[type] ?? [];
  }
  public getTasks$(type: TaskType) {
    return this._getTasks$.pipe(
      map(data => data[type]),
      filter(myData => !!myData),
      map(tasks =>
        tasks.sort((a, b) => {
          if (a.deadline === b.deadline) {
            return a.criticality < b.criticality ? -1 : 1;
          }
          return compareAsc(new Date(a.deadline || 0), new Date(b.deadline || 0));
        })
      )
    );
  }
  public fetchTasks(type: TaskType, status: TaskJobStatus[] = []) {
    const requestType = type === 'responsible' ? 'GetResponsibleTasks' : 'GetRequesterTasks';
    return this._serverService
      .request<TaskJobLight[]>(
        new Request({
          type: requestType,
          content: {
            statusFlag: status.reduce((sum, number) => sum + number, 0),
          },
        })
      )
      .pipe(
        tap(entity => {
          const entities = this._getTasks$.getValue();
          entities[type] = entity;
          this._getTasks$.next(entities);
        })
      );
  }

  public postTask(task: TaskJobPost) {
    return this._serverService.request<null>(
      new Request({
        type: 'PostTask',
        content: task,
      })
    );
  }

  public putTask(id: number, task: TaskJobPut) {
    return this._serverService
      .request<null>(
        new Request({
          type: 'PutTask',
          content: {
            ...task,
            ...{ id },
          },
        })
      )
      .pipe(switchMap(() => this.fetchTask(id)));
  }

  public deleteTask(id: number) {
    return this._serverService.request<null>(
      new Request({
        type: 'DeleteTask',
        content: {
          id,
        },
      })
    );
  }

  public postSubTask(id: number, task: TaskJobPost) {
    return this._serverService.request<null>(
      new Request({
        type: 'PostSubTask',
        content: {
          ...task,
          ...{ id },
        },
      })
    );
  }
  public getTask$(id: number) {
    return this._getTask$.pipe(
      map(data => data[id]),
      filter(myData => !!myData)
    );
  }
  public getTask(id: number) {
    return this._getTask$.getValue()[id];
  }
  public fetchTask(id: number) {
    return this._serverService
      .request<TaskJob>(
        new Request({
          type: 'GetTask',
          cacheTime: 0,
          content: {
            id,
          },
        })
      )
      .pipe(
        tap(entity => {
          const entities = this._getTask$.getValue();
          entities[id] = entity;
          this._getTask$.next(entities);
        })
      );
  }

  public getSubTasks$(id: number) {
    return this._getSubTasks$.pipe(
      map(data => data[id]),
      filter(myData => !!myData)
    );
  }
  public fetchSubTasks(id: number) {
    return this._serverService
      .request<TaskJobLight[]>(
        new Request({
          type: 'GetSubTask',
          cacheTime: 0,
          content: { id },
        })
      )
      .pipe(
        tap(entity => {
          const entities = this._getSubTasks$.getValue();
          entities[id] = entity;
          this._getSubTasks$.next(entities);
        })
      );
  }

  public fetchTaskType$() {
    return this._serverService
      .request<TranslatedEnumeration[]>(
        new Request({
          type: 'GetTaskTypes',
          cacheTime: -1,
        })
      )
      .pipe(
        map(entities => sortByTranslatedValue(entities)),
        tap(entities => {
          this.getTaskTypes$.next(entities);
        })
      );
  }
}
