<app-layout-first-level>
  <app-layout-title>
    <cam-title [level]="2" [isTheme]="false">{{ 'stock.van.list.title' | translate }}</cam-title>
  </app-layout-title>
  <app-layout-content>
    <app-vans-list></app-vans-list>
  </app-layout-content>
  <app-layout-nav>
    <app-menu-main></app-menu-main>
  </app-layout-nav>
</app-layout-first-level>
