<cam-layout-page>
  <cam-layout-header>
    <cam-layout-header-default [title]="this.pageTitle"></cam-layout-header-default>
  </cam-layout-header>

  <div *ngIf="this.detailParams">
    <ng-container [ngSwitch]="this.scope">
      <ng-container *ngSwitchCase="'missions'">
        <app-mission-container [detailParams]="this.detailParams"></app-mission-container>
      </ng-container>
      <ng-container *ngSwitchCase="'incidents'">
        <app-incident-container [detailParams]="this.detailParams"></app-incident-container>
      </ng-container>
      <ng-container *ngSwitchCase="'pictures'">
        <app-visits-pics [detailParams]="this.detailParams"></app-visits-pics>
      </ng-container>
      <ng-container *ngSwitchCase="'files'">
        <app-visits-documents [detailParams]="this.detailParams"></app-visits-documents>
      </ng-container>
      <ng-container *ngSwitchCase="'comments'">
        <app-visits-comments-list [detailParams]="this.detailParams"></app-visits-comments-list>
      </ng-container>
      <ng-container *ngSwitchCase="'checklists'">
        <app-visit-checklists [detailParams]="this.detailParams"></app-visit-checklists>
      </ng-container>
    </ng-container>
  </div>

  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
