<cam-layout-page *ngLet="this.profileName$ | async as profile">
  <cam-layout-header>
    <cam-layout-header-default [showBack]="false" [menuTemplate]="menuTemplate"></cam-layout-header-default>
  </cam-layout-header>

  <ng-template #menuTemplate>
    <app-menu-user></app-menu-user>
  </ng-template>

  <div class="cards-container">
    <ng-container *ngIf="(this._userService.isAuthenticated$ | async) === false">
      <cam-hello-user [title]="'base.home.hello'" [footer]="'base.home.guest'"></cam-hello-user>
      <app-disconnected-card></app-disconnected-card>
    </ng-container>

    <cam-guard *ngIf="this.authService.isAuthenticated$ | async" level="authenticated" [canDisplayErrorMessage]="false">
      <div class="cards-container">
        <cam-hello-user
          *ngIf="profile && profile.naming"
          [title]="'base.home.hello'"
          bulletSize="xl"
          [userInfo]="profile"
        >
        </cam-hello-user>

        <app-daily-visits></app-daily-visits>

        <app-daily-reminders *ngIf="this.canAccessReminders"></app-daily-reminders>

        <cam-guard level="read" feature="worker" [canDisplayErrorMessage]="false">
          <app-working-site-home [status]="this.productionVoucherStatus.ToAdjust"></app-working-site-home>
          <app-working-site-home [status]="this.productionVoucherStatus.Delayed"></app-working-site-home>
        </cam-guard>
      </div>
    </cam-guard>

    <div class="row">
      <div class="col-12 col-md-6 mb-xs" *ngIf="this.authService.isAuthenticated$ | async">
        <app-teams-support></app-teams-support>
      </div>

      <div class="col-12 col-md-6 mb-xs">
        <app-download-installation></app-download-installation>
      </div>
    </div>

    <div class="ta-c">
      <small>Version: {{ this.version }}</small>
    </div>
  </div>

  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
