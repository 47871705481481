import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { compareDesc } from 'date-fns';
import { BehaviorSubject, Observable, Subject, filter, tap } from 'rxjs';

import { CamBaseService, MappingApiType, Request } from '@camelot/server';

import { ExitVoucher } from './dto/exit-voucher';
import { ExitVoucherLight } from './dto/exit-voucher-light';
import { WarehouseItemPost } from './dto/post/returned-warehouse-item';
import { ReturnVoucher } from './dto/return-voucher';
import { ReturnVoucherLight } from './dto/return-voucher-light';
import { Warehouse } from './dto/warehouse';
import { WarehouseLight } from './dto/warehouse-light';
import { WarehouseType } from './dto/warehouse-type';

const apiRoutes: MappingApiType = {
  GetWarehouses: {
    type: 'GET',
    url: '{ApiUrl}/stocks/warehouses?type={type}',
  },
  GetWarehouseDetail: {
    type: 'GET',
    url: '{ApiUrl}/stocks/warehouses/{vanId}',
  },
  GetExitVouchers: {
    type: 'GET',
    url: '{ApiUrl}/stocks/warehouses/{vanId}/exitvouchers',
  },
  GetExitVoucherDetail: {
    type: 'GET',
    url: '{ApiUrl}/stocks/warehouses/{vanId}/exitvouchers/{exitVoucherId}',
  },
  GetReturnVouchers: {
    type: 'GET',
    url: '{ApiUrl}/stocks/warehouses/{vanId}/returnvouchers',
  },
  GetReturnVoucherDetail: {
    type: 'GET',
    url: '{ApiUrl}/stocks/warehouses/{vanId}/returnvouchers/{returnVoucherId}',
  },
  PostReturnVoucher: {
    type: 'POST',
    url: '{ApiUrl}/stocks/warehouses/{vanId}/returnvouchers',
  },
  PutReturnVoucher: {
    type: 'PUT',
    url: '{ApiUrl}/stocks/warehouses/{vanId}/returnvouchers/{returnVoucherId}',
  },
};

@Injectable({
  providedIn: 'root',
})
export class VanService extends CamBaseService {
  private _warehouses$ = new BehaviorSubject<{ [index: number]: WarehouseLight[] }>({});
  private _exitVouchers$ = new BehaviorSubject<{ [index: number]: ExitVoucherLight[] }>({});
  private _warehouseDetail$ = new BehaviorSubject<{ [index: number]: Warehouse }>({});

  private _exitVoucherDetail$ = new BehaviorSubject<{ [index: number]: ExitVoucher }>({});

  private _returnVouchers$ = new BehaviorSubject<{ [index: number]: ReturnVoucherLight[] }>({});
  private _returnVoucherDetail$ = new BehaviorSubject<{ [index: number]: ReturnVoucher }>({});

  constructor() {
    super(apiRoutes);
  }

  public getWarehouses$ = (type: WarehouseType): Observable<WarehouseLight[]> =>
    this._warehouses$.pipe(
      map(data => data[type]),
      filter(myData => !!myData),
      map(warehouses => warehouses.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)))
    );

  public fetchWarehouses$(data: { type: WarehouseType }) {
    return this._serverService
      .request<WarehouseLight[]>(new Request({ type: 'GetWarehouses', content: data, cacheTime: 0 }))
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this._warehouses$.getValue();
          entities[data.type] = entity;
          this._warehouses$.next(entities);
        })
      );
  }

  public getWarehouseDetail$ = (vanId: number) =>
    this._warehouseDetail$.pipe(
      map(data => data[vanId]),
      filter(myData => !!myData)
    );

  public fetchWarehouseDetail$(data: { vanId: number }) {
    return this._serverService
      .request<Warehouse>(new Request({ type: 'GetWarehouseDetail', content: data, cacheTime: 0 }))
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this._warehouseDetail$.getValue();
          entities[data.vanId] = entity;
          this._warehouseDetail$.next(entities);
        })
      );
  }

  public getExitVouchers$ = (vanId: number) =>
    this._exitVouchers$.pipe(
      map(data => data[vanId]),
      filter(myData => !!myData),
      map(exitVouchers =>
        exitVouchers.sort((a, b) => compareDesc(new Date(a.issueDate || ''), new Date(b.issueDate || '')))
      )
    );

  public fetchExitVouchers$(data: { vanId: number }) {
    return this._serverService
      .request<ExitVoucherLight[]>(new Request({ type: 'GetExitVouchers', content: data }))
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this._exitVouchers$.getValue();
          entities[data.vanId] = entity;
          this._exitVouchers$.next(entities);
        })
      );
  }

  public getExitVoucherDetail$ = (exitVoucherId: number) =>
    this._exitVoucherDetail$.pipe(
      map(data => data[exitVoucherId]),
      filter(myData => !!myData)
    );

  public fetchExitVoucherDetail$(data: { exitVoucherId: number }) {
    return this._serverService.request<ExitVoucher>(new Request({ type: 'GetExitVoucherDetail', content: data })).pipe(
      filter(data => !!data),
      tap(entity => {
        const entities = this._exitVoucherDetail$.getValue();
        entities[data.exitVoucherId] = entity;
        this._exitVoucherDetail$.next(entities);
      })
    );
  }

  public getReturnVoucherDetail$ = (returnVoucherId: number) =>
    this._returnVoucherDetail$.pipe(
      map(data => data[returnVoucherId]),
      filter(myData => !!myData)
    );

  public fetchReturnVoucherDetail$(data: { vanId: number; returnVoucherId: number }) {
    return this._serverService
      .request<ReturnVoucher>(new Request({ type: 'GetReturnVoucherDetail', content: data }))
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this._returnVoucherDetail$.getValue();
          entities[data.returnVoucherId] = entity;
          this._returnVoucherDetail$.next(entities);
        })
      );
  }

  public getReturnVouchers$ = (vanId: number) =>
    this._returnVouchers$.pipe(
      map(data => data[vanId]),
      filter(myData => !!myData),
      map(returnVouchers => returnVouchers.sort((a, b) => (a.createdTime < b.createdTime ? -1 : 1)))
    );

  public fetchReturnVouchers$(data: { vanId: number }) {
    return this._serverService
      .request<ReturnVoucherLight[]>(new Request({ type: 'GetReturnVouchers', content: data }))
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this._returnVouchers$.getValue();
          entities[data.vanId] = entity;
          this._returnVouchers$.next(entities);
        })
      );
  }

  public getReturnVoucher$ = (returnVoucherId: number) =>
    this._returnVoucherDetail$.pipe(
      map(data => data[returnVoucherId]),
      filter(myData => !!myData)
    );

  public addReturnVoucher$(data: { vanId: number; warehouseItems: WarehouseItemPost[] }): Subject<void> {
    return this._serverService.request(
      new Request({
        type: 'PostReturnVoucher',
        content: {
          vanId: data.vanId,
          items: data.warehouseItems,
        },
      })
    );
  }

  public editReturnVoucher$(
    vanId: number,
    returnVoucherId: number,
    items: {
      itemId: number;
      quantity: number;
    }[]
  ): Subject<void> {
    return this._serverService.request(
      new Request({
        type: 'PutReturnVoucher',
        urlData: { vanId: vanId, returnVoucherId: returnVoucherId },
        content: {
          items: items.map(item => ({
            id: item.itemId,
            quantity: item.quantity,
          })),
        },
      })
    );
  }
}
