import { CriticalityStatus } from 'src/app/services/tasks/dto/criticality-status';
import { TaskJobStatus } from 'src/app/services/tasks/dto/task-job-status';

export const criticalityType = (criticality: CriticalityStatus) => {
  switch (criticality) {
    case CriticalityStatus.P1:
      return 'danger';
    case CriticalityStatus.P2:
      return 'warning';
    case CriticalityStatus.P3:
      return 'success';
    default:
      return 'primary';
  }
};

export const statusTraduction = (status: TaskJobStatus) => {
  return `tasks.filters.options.status.${TaskJobStatus[status].toLocaleLowerCase()}`;
};

/*
 ** @deprecated
 */
type BadgeType = 'danger' | 'warning' | 'success' | 'primary' | 'secondary' | 'info';

export const statusBadgeType = (status: TaskJobStatus): BadgeType => {
  switch (status) {
    case TaskJobStatus.Open:
      return 'success';
    case TaskJobStatus.Stalled:
      return 'warning';
    case TaskJobStatus.Cancelled:
      return 'danger';
    case TaskJobStatus.ClosedWithFailure:
    case TaskJobStatus.ClosedWithSuccess:
    default:
      return 'info';
  }
};
