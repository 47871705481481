<cam-layout-page>
  <cam-layout-header>
    <cam-layout-header-default [title]="this.pageTitle"></cam-layout-header-default>
  </cam-layout-header>
  <cam-layout-title>
    <ng-container *ngIf="this.detailParams">
      <div class="p-space-lg">
        <app-visit-detail [detailParams]="this.detailParams"> </app-visit-detail>
      </div>
    </ng-container>
  </cam-layout-title>

  <cam-context-menu *ngIf="this.menu" [menu]="this.menu"></cam-context-menu>

  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
