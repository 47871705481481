<cam-layout-page *ngLet="this.data$ | async as detail">
  <cam-layout-header>
    <cam-layout-header-default [title]="this.pageTitle"></cam-layout-header-default>
  </cam-layout-header>
  <cam-layout-title>
    <app-offer-request-detail *ngIf="this.id" [id]="this.id"></app-offer-request-detail>
  </cam-layout-title>

  <cam-layout-content *ngIf="detail">
    <div>
      <app-offer-request-informations [offerRequest]="detail"></app-offer-request-informations>
    </div>
    <div class="mb-space-xxl" *ngIf="detail.priceOffers.length > 0">
      <app-price-offer-list [offers]="detail.priceOffers"></app-price-offer-list>
    </div>
    <div class="mb-space-xxl">
      <app-prospections-list [prospections]="detail.prospections"></app-prospections-list>
    </div>

    <cam-context-menu *ngIf="this.menu" [menu]="this.menu"></cam-context-menu>
  </cam-layout-content>

  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
