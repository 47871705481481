import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';

import { Observable, map } from 'rxjs';

import { Menu, MenuIcon, MenuPanel } from '@camelot/menu';
import { CamSizes } from '@camelot/styles';
import { CamTranslationService } from '@camelot/translation';
import { UserLogoNaming } from '@camelot/ui';

import { environment } from '../../../../../environments/environment';
import { BaseComponent } from '../../../core/abstract/baseComponent';

@Component({
  selector: 'app-menu-user',
  templateUrl: './menu-user.component.html',
  styleUrls: ['./menu-user.component.scss'],
})
export class MenuUserComponent extends BaseComponent implements OnDestroy {
  get isAuthenticated$() {
    return this._userService.isAuthenticated$;
  }

  get version() {
    return environment.version;
  }

  get profile$() {
    return this._userProfileService.getUserProfile$.pipe(
      map(data => {
        return {
          title: {
            second: data?.firstName + ' ' + data?.lastName,
          },
        };
      })
    );
  }

  get userLogo$(): Observable<{
    userInfo: {
      profilePictureUrl?: string;
      naming: UserLogoNaming;
    };
    size?: CamSizes;
  } | null> {
    return this._userProfileService.getUserProfile$.pipe(
      map(x => {
        if (!x) {
          return null;
        }

        return {
          userInfo: {
            profilePictureUrl: x.profilePictureUrl,
            naming: {
              name: x.firstName || '',
              firstName: x.lastName || '',
              trigram: x.trigram || '',
            },
          },
          size: 'lg',
        };
      })
    );
  }

  @ViewChild('languageTemplate', { static: true }) languageTemplate!: TemplateRef<any>;

  public profileMenu: Menu | null = null;
  public disconnectionMenu: Menu | null = null;
  public activeLanguage: string;
  public changeLanguageAsked: boolean = false;
  public languages = [
    { id: 'fr', name: 'Français' },
    { id: 'nl', name: 'Nederlands' },
    { id: 'en', name: 'English' },
    { id: 'es', name: 'Español' },
  ];

  constructor(public translateService: CamTranslationService) {
    super();

    this.activeLanguage = this.translateService.getLanguage();
  }

  ngAfterViewChecked() {
    this.profileMenu = this.getProfileMenu(this.languageTemplate);
    this.disconnectionMenu = this.getDisconnectionMenu();
  }

  public login() {
    this._userService.login();
  }

  public logout() {
    this._userService.logout();
  }

  public changeLanguage(language: string) {
    if (this.activeLanguage != language) {
      this.activeLanguage = language;
      this.changeLanguageAsked = true;
      this.translateService.use(language);
    }
  }

  private getProfileMenu(languageTemplate: TemplateRef<any>) {
    const menu = [
      new MenuPanel({
        key: 'language',
        label: 'user.language',
        order: 1,
        template: languageTemplate,
        style: 'dark',
        icon: 'language',
        iconsColor: 'icon-color-icon-tertiary',
        endingIcon: 'arrow-big-right',
      }),
      new MenuIcon({
        key: 'helpcenter',
        label: 'user.help-center',
        order: 2,
        style: 'dark',
        icon: 'help',
        iconsColor: 'icon-color-icon-tertiary',
        callback: () => this.navigateToHelpCenter(),
      }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'vertical',
    });
  }

  private getDisconnectionMenu() {
    const menu = [
      new MenuIcon({
        key: 'logout',
        label: 'user.logout',
        order: 4,
        style: 'dark',
        icon: 'log-out',
        iconsColor: 'icon-color-icon-tertiary',
        callback: () => this.logout(),
      }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'vertical',
    });
  }

  private navigateToHelpCenter() {
    window.open('https://help.merlinsoftware.be/fr/collections/10134792-tristan');
  }
}
