<div class="py-xl">
  <div class="ta-r space-between">
    <cam-link (action)="this.launchClientReturn()">{{ 'reminders.card.full.client-return' | translate }}</cam-link>
    <cam-button type="secondary" [options]="{ circular: 'small' }" (action)="this.goToClient()">
      <cam-font-icon name="contacts"></cam-font-icon>
    </cam-button>
  </div>

  <cam-profile-data class="profile" [profile]="this.profile" (callAction)="this.launchedCall()"></cam-profile-data>
</div>

<ng-template #remindersTemplate let-type="type">
  <app-reminder-dialog
    *ngIf="this.reminders.length > 0"
    [reminders]="this.reminders"
    [type]="type"
    [formSubmit$]="this.formSubmit$"
  ></app-reminder-dialog>
</ng-template>

<ng-template #prospectionsTemplate let-type="type">
  <app-prospection-dialog
    *ngIf="this.prospections.length > 0"
    [prospections]="this.prospections"
    [type]="type"
    [formSubmit$]="this.formSubmit$"
  ></app-prospection-dialog>
</ng-template>
