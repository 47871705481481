import { Component } from '@angular/core';

import { AppMenuSecondService } from 'src/app/services/menus/second.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  template: `
    <cam-layout-page>
      <cam-layout-header>
        <cam-title [level]="2">{{ 'tasks.pages.list.title' | translate }}</cam-title>
      </cam-layout-header>
      <cam-layout-content>
        <cam-menu-navigation [menu]="this.menu" container="tags"></cam-menu-navigation>
        <router-outlet></router-outlet>
      </cam-layout-content>
      <cam-layout-nav>
        <app-menu-main></app-menu-main>
      </cam-layout-nav>
    </cam-layout-page>
  `,
})
export class TasksListBasePage {
  public menu = this._menuService.getTaskMenu();

  constructor(private _menuService: AppMenuSecondService) {}
}
