import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';
import { AuthGuard } from '@camelot/user';

import { TaskDetailPage } from './pages/detail/detail.component';
import { TasksSubDetailPage } from './pages/detail/sub/tasks-sub-detail.component';
import { TasksListBasePage } from './pages/list-base.component';
import { TaskRequesterPage } from './pages/requester/task-requester.component';
import { TaskResponsiblePage } from './pages/responsible/task-responsible.component';

export enum ETaskRoute {
  TASK = 'task',
  LIST = 'list',
  RESPONSIBLE = 'responsible',
  REQUESTER = 'requester',
  DETAIL = 'detail',
  SUBDETAIL = 'subdetail',
}

export class AppTasksRoutingModule {
  public static loadRoutes() {
    CamRoutes.addRoute({
      key: ETaskRoute.TASK,
      url: 'tasks',
      children: [
        {
          key: ETaskRoute.LIST,
          url: 'list',
          children: [
            {
              key: ETaskRoute.RESPONSIBLE,
              url: 'responsible',
            },
            {
              key: ETaskRoute.REQUESTER,
              url: 'requester',
            },
          ],
        },
        {
          key: ETaskRoute.DETAIL,
          url: 'detail/:id',
        },
        {
          key: ETaskRoute.SUBDETAIL,
          url: 'detail/:id/:scope',
        },
      ],
    });
  }

  public static getRouting(): Routes {
    return [
      // only for redirection
      {
        path: '',
        redirectTo: CamRoutes.getAbsoluteUrl([ETaskRoute.TASK, ETaskRoute.LIST, ETaskRoute.RESPONSIBLE]),
        pathMatch: 'full',
      },
      {
        path: CamRoutes.getUrl([ETaskRoute.TASK, ETaskRoute.LIST]),
        component: TasksListBasePage,
        canActivate: [AuthGuard],
        children: [
          {
            path: CamRoutes.getUrl([ETaskRoute.TASK, ETaskRoute.LIST, ETaskRoute.RESPONSIBLE]),
            component: TaskResponsiblePage,
          },
          {
            path: CamRoutes.getUrl([ETaskRoute.TASK, ETaskRoute.LIST, ETaskRoute.REQUESTER]),
            component: TaskRequesterPage,
          },
        ],
      },
      {
        path: CamRoutes.getUrl([ETaskRoute.TASK, ETaskRoute.DETAIL]),
        component: TaskDetailPage,
        canActivate: [AuthGuard],
      },
      {
        path: CamRoutes.getUrl([ETaskRoute.TASK, ETaskRoute.SUBDETAIL]),
        component: TasksSubDetailPage,
      },
    ];
  }
}
