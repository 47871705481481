<cam-layout-page>
  <cam-layout-header>
    <div>
      <app-calendar-navigation
        [view]="this.view"
        [viewDate]="this.viewDate"
        (viewDateChanged)="this.viewDateChanged($event)"
        (selectedMonth)="this.showMonth()"
      ></app-calendar-navigation>
    </div>
  </cam-layout-header>

  <div class="title-container align-center">
    <cam-title [level]="4">{{ 'visits.list.by-day.title' | translate }}</cam-title>
    <cam-menu-navigation [menu]="this.menu" container="tags"></cam-menu-navigation>
  </div>
  <router-outlet></router-outlet>
  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>

<ng-template #monthVisitsTemplate>
  <app-month-bottom [viewDate]="this.viewDate" (closeBottom)="this.closeBottom($event)"></app-month-bottom>
</ng-template>
