import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';

import { WorkingSiteDetailPage } from './pages/detail/working-site-detail.component';
import { WorkingSiteSubDetailPage } from './pages/detail/working-site-sub-detail/working-site-sub-detail.component';

export enum EWorkingSiteRoute {
  WORKINGSITE = 'working-site',
  DETAIL = 'detail',
  SUBDETAIL = 'sub-detail',
}

export class AppWorkingSiteRoutingModule {
  public static loadRoutes() {
    CamRoutes.addRoute({
      key: EWorkingSiteRoute.WORKINGSITE,
      url: 'workingsite',
      children: [
        {
          key: EWorkingSiteRoute.DETAIL,
          url: 'detail/:id',
        },
        {
          key: EWorkingSiteRoute.SUBDETAIL,
          url: 'detail/:id/:scope',
        },
      ],
    });
  }

  public static getRouting(): Routes {
    return [
      {
        path: CamRoutes.getUrl([EWorkingSiteRoute.WORKINGSITE, EWorkingSiteRoute.DETAIL]),
        component: WorkingSiteDetailPage,
      },
      {
        path: CamRoutes.getUrl([EWorkingSiteRoute.WORKINGSITE, EWorkingSiteRoute.SUBDETAIL]),
        component: WorkingSiteSubDetailPage,
      },
    ];
  }
}
