import { Component, OnInit } from '@angular/core';

import { map, of } from 'rxjs';

import { CamRoutes, MenuIcon } from '@camelot/menu';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { Person } from 'src/app/services/persons/dto/person';
import { AppPersonsService } from 'src/app/services/persons/persons.service';

import { ETierRoute } from '../../routing.module';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './tiers-detail.component.html',
  styleUrls: ['./tiers-detail.component.scss'],
})
export class TiersDetailPage extends BasePage implements OnInit {
  public personId: number | null = null;
  public person!: Person;

  get person$() {
    if (this.personId) {
      return this.personsService.getPerson$(this.personId);
    }
    return of();
  }

  constructor(public personsService: AppPersonsService) {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getPathParams({ id: 0 }).subscribe(({ id }) => {
        this._setPageInformation({
          level: EPageLevel.Details,
          name: 'tiers.pages.detail.title',
        });

        this.personId = id ?? null;
        if (this.personId !== 0) {
          this.personsService.fetchPerson(this.personId);
        }
      })
    );
  }

  public getPersonMenu() {
    return {
      elements: [
        new MenuIcon({
          key: 'OfferRequests',
          label: 'tiers.pages.external.offer',
          link: CamRoutes.getAbsoluteUrl([ETierRoute.TIER, ETierRoute.EXTERNAL, ETierRoute.SUBDETAIL], {
            id: this.personId,
            scope: 'offer-requests',
          }),
          order: 1,
          icon: 'home',
          exact: true,
        }),

        new MenuIcon({
          key: 'WorkingSites',
          label: 'tiers.pages.external.working-site',
          link: CamRoutes.getAbsoluteUrl([ETierRoute.TIER, ETierRoute.EXTERNAL, ETierRoute.SUBDETAIL], {
            id: this.personId,
            scope: 'working-sites',
          }),
          order: 1,
          icon: 'team-workers',
          exact: true,
        }),
        new MenuIcon({
          key: 'Contacts',
          label: 'tiers.pages.external.contacts',
          link: CamRoutes.getAbsoluteUrl([ETierRoute.TIER, ETierRoute.EXTERNAL, ETierRoute.SUBDETAIL], {
            id: this.personId,
            scope: 'contact-persons',
          }),
          order: 2,
          icon: 'contacts',
          visible$: this.person$.pipe(map(person => person.contacts !== null)),
        }),
      ],
      direction: 'horizontal',
    };
  }
}
