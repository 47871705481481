<div class="status-container mt-space-md" (click)="this.openBottomStatus()">
  <cam-badge
    class="mr-space-xs"
    *ngFor="let status of this.getActiveStatusFilter()"
    type="info"
    [value]="status.name"
    [showClickOption]="true"
  ></cam-badge>
</div>

<cam-filters-container
  [form]="this.form"
  [activeFilter]="this.formattedActiveFilters"
  (filtersSelected)="this.setFilters($event)"
  (removedFilter)="this.removedFilter($event)"
>
  <cam-loader
    *ngLet="this.filteredData$ | async as tasks"
    [isLoading]="this.requestState.isLoading()"
    skeleton="detail"
  >
    <cam-action-button [actions]="this.addActions" class="floating-add"></cam-action-button>

    <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
      <cam-empty [isEmpty]="!tasks || tasks.length === 0">
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
          <div *ngFor="let task of tasks" class="mb-2">
            <app-task-card [task]="task"></app-task-card>
          </div>
        </div>
      </cam-empty>
    </cam-error>
  </cam-loader>
</cam-filters-container>

<ng-template #addTasksTemplate let-task="task">
  <app-form-bottom (changeMade)="this.closeTaskBottom()"></app-form-bottom>
</ng-template>
