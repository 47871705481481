import { Component, OnInit } from '@angular/core';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';

import { PageScope } from '../../../types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './offer-request-sub-detail.component.html',
  styleUrls: ['./offer-request-sub-detail.component.scss'],
})
export class OfferRequestSubDetailPage extends BasePage implements OnInit {
  public id: number = 0;

  public scope: PageScope = '';

  constructor() {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getPathParams<{ id: number; scope: PageScope }>({ id: 0, scope: '' }).subscribe(({ id, scope }) => {
        this._setPageInformation({
          level: EPageLevel.Sub,
          name: 'offer-request.pages.sub.' + scope,
        });

        this.id = id;
        this.scope = scope;
      })
    );
  }
}
