<cam-card *ngIf="this.getIosLink() || this.getAndroidLink()">
  <cam-card-content>
    <div class="flex-column m-a">
      <cam-title [level]="3" [isTheme]="true" class="title">
        {{ 'base.home.card.download-installation-title' | translate }}
      </cam-title>

      <div class="space-between">
        <div (click)="this.appStore()" *ngIf="this.getIosLink()">
          <cam-title [level]="4" class="subtitle">{{ 'iOS' }}</cam-title>
          <cam-local-icon [type]="this.icon.AppStore" size="md"></cam-local-icon>
        </div>
        <div (click)="this.playStore()" *ngIf="this.getAndroidLink()">
          <cam-title [level]="4" class="subtitle">{{ 'Android' }}</cam-title>
          <cam-local-icon [type]="this.icon.PlayStore" size="md"></cam-local-icon>
        </div>
      </div>
    </div>
  </cam-card-content>
</cam-card>
