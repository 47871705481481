<ng-container *ngLet="this.data$ | async as detail">
  <cam-layout-page>
    <cam-layout-header *ngIf="detail">
      <cam-layout-header-default [title]="this.pageTitle"></cam-layout-header-default>
    </cam-layout-header>
    <cam-layout-title *ngIf="detail">
      <app-working-site-title [person]="detail.client" [askForCall$]="this.askForCall$"></app-working-site-title>
    </cam-layout-title>

    <cam-layout-content *ngIf="detail">
      <div class="my-space-xxxl">
        <app-working-site-reminders-list
          [workingSiteReminders]="this.filterReminders(detail.workingSiteReminders)"
          [askForCall$]="this.askForCall$"
        ></app-working-site-reminders-list>
      </div>
      <div class="pb-space-lg">
        <app-working-site-informations [workingSite]="detail"></app-working-site-informations>
      </div>
      <cam-context-menu *ngIf="this.menu" [menu]="this.menu"></cam-context-menu>
    </cam-layout-content>

    <cam-layout-nav>
      <app-menu-main></app-menu-main>
    </cam-layout-nav>
  </cam-layout-page>
</ng-container>
