<cam-card>
  <cam-card-header>
    <cam-card-tag class="space-between">
      {{ this.workingSite.translatedStatus.translatedValue }}
    </cam-card-tag>
    <cam-card-title>
      {{ this.workingSite.name }}
    </cam-card-title>
  </cam-card-header>

  <cam-card-content>
    <div class="row">
      <cam-department-professions [professions]="this.professions"></cam-department-professions>
    </div>
  </cam-card-content>

  <cam-card-cta>
    <div class="content">
      <div class="address align-center">
        <cam-font-icon name="pin" type="sm"></cam-font-icon>

        <div class="street">
          {{ this.street }}
        </div>

        <div class="locality" *ngIf="this.workingSite.address.postalCode && this.workingSite.address.locality">
          {{ this.workingSite.address.postalCode }}, {{ this.workingSite.address.locality }}
        </div>
      </div>
    </div>
  </cam-card-cta>
</cam-card>
