import { Component } from '@angular/core';

import { CamMainRoute, CamRoutes } from '@camelot/menu';

import { BasePage } from '../../core/abstract/basePage';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
})
export class ComingSoonPage extends BasePage {
  constructor() {
    super();
  }

  public goToHome() {
    this._router.navigateByUrl(CamRoutes.getAbsoluteUrl([CamMainRoute.HOME]));
  }
}
