import { Component } from '@angular/core';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';

@Component({
  selector: 'app-disconnected-card',
  templateUrl: './disconnected-card.component.html',
  styleUrls: ['./disconnected-card.component.scss'],
})
export class HomeDisconnectedCardComponent extends BaseComponent {
  constructor() {
    super();
  }

  public login() {
    this._userService.login();
  }
}
