<cam-layout-page>
  <cam-layout-header>
    <cam-layout-header-default [title]="this.pageTitle"></cam-layout-header-default>
  </cam-layout-header>
  <cam-layout-content>
    <cam-guard feature="person" level="read">
      <div class="list row g-0">
        <div *ngIf="this.warehouseId">
          <ng-container [ngSwitch]="this.scope">
            <ng-container *ngSwitchCase="'exit-vouchers'">
              <app-exit-vouchers [warehouseId]="this.warehouseId"></app-exit-vouchers>
            </ng-container>
            <ng-container *ngSwitchCase="'return-vouchers'">
              <app-return-vouchers [warehouseId]="this.warehouseId"></app-return-vouchers>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </cam-guard>
  </cam-layout-content>
  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
