import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CamIconsModule } from '@camelot/icons';
import { CamMenuModule } from '@camelot/menu';
import { CamCardModule, CamContainerModule, CamLayoutModule, CamSwiperModule, CamUiModule } from '@camelot/ui';
import { CamUserModule } from '@camelot/user';
import { CamDirectivePipeModule } from '@camelot/utils';

import { AppBaseModule } from '../base.module';
import { HomeCurrentVisitComponent } from './card/current-visit/current-visit.component';
import { DailyRemindersComponent } from './card/daily-reminders/daily-reminders.component';
import { DailyVisitsComponent } from './card/daily-visits/daily-visits.component';
import { HomeDisconnectedCardComponent } from './card/disconnected/disconnected-card.component';
import { DownloadInstallationComponent } from './card/download-installation/download-installation.component';
import { StatsCardComponent } from './card/stats/stats-card.component';
import { TeamsSupportComponent } from './card/teams-support/teams-support.component';
import { WorkingSiteHomeComponent } from './card/working-site-home/working-site-home.component';
import { HomePage } from './home.component';

@NgModule({
  declarations: [
    DailyRemindersComponent,
    DailyVisitsComponent,
    DownloadInstallationComponent,
    HomeCurrentVisitComponent,
    HomeDisconnectedCardComponent,
    HomePage,
    StatsCardComponent,
    TeamsSupportComponent,
    WorkingSiteHomeComponent,
  ],
  imports: [
    AppBaseModule,
    CamCardModule,
    CamContainerModule,
    CamDirectivePipeModule,
    CamIconsModule,
    CamLayoutModule,
    CamMenuModule,
    CamSwiperModule,
    CamUiModule,
    CamUserModule,
    CommonModule,
  ],
  exports: [HomePage],
})
export class AppHomeModule {}
