import { Component } from '@angular/core';

import { EPageLevel } from '../../../../../core/navigation/navigation.service';
import { BasePage } from '../../../../core/abstract/basePage';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './vans-list-page.component.html',
  styleUrls: ['./vans-list-page.component.scss'],
})
export class VansListPage extends BasePage {
  constructor() {
    super();

    this._setPageInformation({
      level: EPageLevel.Main,
      name: 'stock.van.list.title',
    });
  }
}
