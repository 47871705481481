<cam-layout-page>
  <cam-layout-header>
    <cam-layout-header-default [title]="this.pageTitle"></cam-layout-header-default>
  </cam-layout-header>
  <cam-layout-content>
    <div *ngIf="this.id">
      <ng-container [ngSwitch]="this.scope">
        <ng-container *ngSwitchCase="'files'">
          <app-working-site-documents [workingSiteId]="this.id"></app-working-site-documents>
        </ng-container>
        <ng-container *ngSwitchCase="'pictures'">
          <app-working-site-pictures [workingSiteId]="this.id"></app-working-site-pictures>
        </ng-container>
        <ng-container *ngSwitchCase="'visits'">
          <app-working-site-visits [workingSiteId]="this.id"></app-working-site-visits>
        </ng-container>
        <ng-container *ngSwitchCase="'comments'">
          <app-working-site-comments [workingSiteId]="this.id"></app-working-site-comments>
        </ng-container>
      </ng-container>
    </div>
  </cam-layout-content>
  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
