<cam-layout-page>
  <cam-layout-header>
    <cam-layout-header-default [title]="this.pageTitle"></cam-layout-header-default>
  </cam-layout-header>
  <cam-layout-content>
    <ng-container *ngIf="this.id">
      <app-task-view
        [id]="this.id"
        [summary]="this.displaySummary"
        (requestFullCard)="this.tabSelected(null)"
      ></app-task-view>

      <div class="mt-space-sm">
        <cam-context-menu *ngIf="this.menu" [menu]="this.menu"></cam-context-menu>
      </div>
    </ng-container>
  </cam-layout-content>

  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
