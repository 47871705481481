<app-layout-first-level>
  <app-layout-header>
    <app-list-header></app-list-header>
  </app-layout-header>
  <app-layout-content>
    <cam-guard feature="person" level="read">
      <app-persons-list [searchTerm]="this.searchTerm"></app-persons-list>
    </cam-guard>
  </app-layout-content>
  <app-layout-nav>
    <app-menu-main></app-menu-main>
  </app-layout-nav>
</app-layout-first-level>
