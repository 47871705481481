import { Injectable } from '@angular/core';

import { compareDesc } from 'date-fns';
import { BehaviorSubject, Observable, filter, map, tap } from 'rxjs';

import { Request } from '@camelot/server';
import { MappingApiType } from '@camelot/server';
import { CamBaseService } from '@camelot/server';

import { OfferRequest } from './dto/offer-request';
import { OfferRequestLight } from './dto/offer-request-light';

const apiRoutes: MappingApiType = {
  GetOfferRequest: {
    type: 'GET',
    url: '{ApiUrl}/offerrequests/{offerRequestId}',
  },
  GetOfferRequestsFromClient: {
    type: 'GET',
    url: '{ApiUrl}/offerrequests/{clientId}/offerrequests',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AppOfferRequestService extends CamBaseService {
  public _offerRequest$ = new BehaviorSubject<{ [id: number]: OfferRequest }>({});
  public _offerRequestsFromClient$ = new BehaviorSubject<{ [id: number]: OfferRequestLight[] }>({});

  constructor() {
    super(apiRoutes);
  }

  public getOfferRequest$ = (id: number): Observable<OfferRequest> =>
    this._offerRequest$.pipe(
      map(data => data[id]),
      filter(myData => !!myData)
    );

  public fetchOfferRequest$(id: number): Observable<OfferRequest> {
    return this._serverService
      .request<OfferRequest>(
        new Request({
          type: 'GetOfferRequest',
          content: { offerRequestId: id },
        })
      )
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this._offerRequest$.getValue();
          entities[id] = entity;
          this._offerRequest$.next(entities);
        })
      );
  }

  public getOfferRequestsFromClient$ = (id: number): Observable<OfferRequestLight[]> =>
    this._offerRequestsFromClient$.pipe(
      map(data => data[id]),
      filter(myData => !!myData),
      map(offers => offers.sort((a, b) => compareDesc(new Date(a.createdTime || ''), new Date(b.createdTime || ''))))
    );

  public fetchOfferRequestsFromClient$(clientId: number) {
    return this._serverService
      .request<OfferRequestLight[]>(
        new Request({
          type: 'GetOfferRequestsFromClient',
          content: { clientId: clientId },
        })
      )
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this._offerRequestsFromClient$.getValue();
          entities[clientId] = entity;
          this._offerRequestsFromClient$.next(entities);
        })
      );
  }
}
