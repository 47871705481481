import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';

import { Subject } from 'rxjs';

import { Menu } from '@camelot/menu';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { ReminderStatus } from 'src/app/services/clientFollowUp/reminders/dto/reminder-status';
import { WorkingSiteReminder } from 'src/app/services/clientFollowUp/workingsite-reminders/dto/workingsite-reminder';
import { AppMenuQuickActionService } from 'src/app/services/menus/quick-action.service';
import { AppWorkingSiteService } from 'src/app/services/workingSite/working-site.service';

@Component({
  selector: '',
  templateUrl: './working-site-detail.component.html',
  styleUrls: ['./working-site-detail.component.scss'],
})
export class WorkingSiteDetailPage extends BasePage {
  public id: number = 0;
  public menu: Menu | null = null;

  public askForCall$: Subject<void> = new Subject<void>();

  get data$() {
    return this._workingSiteService.getWorkingSite$(this.id);
  }

  constructor(private _workingSiteService: AppWorkingSiteService, private _menuService: AppMenuQuickActionService) {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getPathParams({ id: 0 }).subscribe(({ id }) => {
        this._setPageInformation({
          level: EPageLevel.Details,
          name: 'working-site.pages.detail.name',
        });

        this.id = id;
      })
    );

    this.menu = this._menuService.getWorkingSiteDetailMenu({ id: this.id });
    this.fetch();
  }

  public fetch() {
    if (!this.id) {
      return;
    }
    this.requestState.asked();
    this._workingSiteService.fetchWorkingSite$({ workingSiteId: this.id }).subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }

  public filterReminders(reminders: WorkingSiteReminder[]) {
    return reminders.filter(x => x.status != ReminderStatus.Done);
  }
}
