import { Injectable } from '@angular/core';

import { CamMainRoute, CamRoutes, Menu } from '@camelot/menu';
import { MenuIcon } from '@camelot/menu';
import { CamBaseService } from '@camelot/server';
import { Permissions } from '@camelot/user';

import { EReminderRoute } from 'src/app/modules/reminders/routing.module';
import { EStockRoute } from 'src/app/modules/stock/van/routing.module';
import { ETaskRoute } from 'src/app/modules/tasks/routing.module';
import { ETierRoute } from 'src/app/modules/tiers/routing.module';
import { EVisitRoute } from 'src/app/modules/visits/routing.module';

@Injectable({
  providedIn: 'root',
})
export class AppMenuMainService extends CamBaseService {
  constructor() {
    super();
  }

  public getMenu(): Menu {
    const menu = [
      new MenuIcon({
        key: 'home',
        label: 'base.menu.main.home',
        link: CamRoutes.getAbsoluteUrl([CamMainRoute.HOME]),
        order: 1,
        icon: 'home',
        exact: true,
      }),
      new MenuIcon({
        key: 'contacts',
        label: 'base.menu.main.contact',
        link: CamRoutes.getAbsoluteUrl([ETierRoute.TIER]),
        order: 2,
        icon: 'contacts',
        visible$: Permissions.canAccess('person', 'read') || Permissions.canAccess('arthur-user', 'read'),
      }),
      new MenuIcon({
        key: 'visit',
        label: 'base.menu.main.visit',
        order: 3,
        icon: 'calendar',
        link: CamRoutes.getAbsoluteUrl([EVisitRoute.VISIT]),
      }),
      new MenuIcon({
        key: 'relance',
        label: 'base.menu.main.reminder',
        link: CamRoutes.getAbsoluteUrl([EReminderRoute.REMINDER]),
        order: 4,
        icon: 'notifications',
      }),
      new MenuIcon({
        key: 'database',
        label: 'base.menu.main.stock',
        link: CamRoutes.getAbsoluteUrl([EStockRoute.STOCK]),
        order: 5,
        icon: 'database',
        visible$: Permissions.canAccess('worker', 'all'),
      }),
      new MenuIcon({
        key: 'task',
        label: 'base.menu.main.task',
        link: CamRoutes.getAbsoluteUrl([ETaskRoute.TASK]),
        order: 6,
        icon: 'tasks',
      }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'responsive',
    });
  }
}
