import { Component, OnInit } from '@angular/core';

import { Menu } from '@camelot/menu';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { AppMenuQuickActionService } from 'src/app/services/menus/quick-action.service';

import { DetailParams } from '../../../visits/types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class TaskDetailPage extends BasePage implements OnInit {
  public id: number | null = null;

  public displaySummary: boolean = false;
  public activeTab: string | null = null;
  public menu: Menu | null = null;

  constructor(private _menuService: AppMenuQuickActionService) {
    super();
  }
  ngOnInit(): void {
    this._registerSubscription(
      this._getPathParams<DetailParams>({ id: 0, type: 0 }).subscribe(({ id }) => {
        this.id = id;
        this.menu = this._menuService.getTaskDetailMenu({ id });
        this.tabSelected(null);

        this._setPageInformation({
          level: EPageLevel.Details,
          name: 'tasks.pages.detail.title',
        });
      })
    );
  }

  public tabSelected(tab: string | null) {
    this.activeTab = tab;
    this.displaySummary = !!tab;
  }
}
