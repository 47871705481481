import { Component } from '@angular/core';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './tiers-external.component.html',
  styleUrls: ['./tiers-external.component.scss'],
})
export class TiersExternalPage extends BasePage {
  public searchTerm: string = '';

  constructor() {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getQueryParams({ search: '' }).subscribe(({ search }) => {
        this._setPageInformation({
          level: EPageLevel.Main,
          name: 'tiers.pages.external.title',
        });
        if (search) this.searchTerm = search;
      })
    );
  }
}
