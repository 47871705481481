import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { Menu } from '@camelot/menu';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { AppMenuQuickActionService } from 'src/app/services/menus/quick-action.service';
import { OfferRequest } from 'src/app/services/offerRequest/dto/offer-request';
import { AppOfferRequestService } from 'src/app/services/offerRequest/offer-request.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './offer-request-detail.component.html',
  styleUrls: ['./offer-request-detail.component.scss'],
})
export class OfferRequestDetailPage extends BasePage implements OnInit {
  public id: number = 0;
  public menu: Menu | null = null;

  get data$() {
    return this._offerRequestService.getOfferRequest$(this.id);
  }

  constructor(private _offerRequestService: AppOfferRequestService, private _menuService: AppMenuQuickActionService) {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getPathParams({ id: 0 }).subscribe(({ id }) => {
        this._setPageInformation({
          level: EPageLevel.Details,
          name: 'offer-request.pages.detail.name',
        });

        this.id = id;
      })
    );

    this.menu = this._menuService.getOfferRequestDetailMenu({ id: this.id });
    this.fetch();
  }

  /**
   * fetch the offer request
   */
  public fetch() {
    if (!this.id) {
      return;
    }
    this.requestState.asked();
    this._offerRequestService.fetchOfferRequest$(this.id).subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }

  public getStatusTranslation(offerRequest: OfferRequest) {
    return offerRequest.translatedStatus.translatedValue;
  }
}
