import { Component, OnInit } from '@angular/core';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './visit-chronological.component.html',
  styleUrls: ['./visit-chronological.component.scss'],
})
export class VisitChronologicalPage extends BasePage implements OnInit {
  public viewDate: Date = new Date();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getQueryParams({ date: '' }).subscribe(({ date }) => {
        this._setPageInformation({
          level: EPageLevel.Main,
          name: 'visits.pages.planned.chronological.title',
        });

        if (date) this.viewDate = new Date(date);
      })
    );
  }
}
