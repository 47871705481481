import { Component } from '@angular/core';

import { of } from 'rxjs';

import { CamRoutes } from '@camelot/menu';

import { EReminderRoute } from 'src/app/modules/reminders/routing.module';
import { EVisitRoute } from 'src/app/modules/visits/routing.module';

import { AppDashboardService } from '../../../../../services/dashboard/dashboard.service';
import { Dashboard } from '../../../../../services/dashboard/dto/dashboard';
import { BaseComponent } from '../../../../core/abstract/baseComponent';
import { StatsCardData } from './stats-card-data';

@Component({
  selector: 'app-stats-card',
  templateUrl: './stats-card.component.html',
  styleUrls: ['./stats-card.component.scss'],
})
export class StatsCardComponent extends BaseComponent {
  public quickActionElements: StatsCardData[] = [];
  public typeToken!: { element: StatsCardData };
  public title: string = 'base.home.stats.day-title';

  constructor(private _dashboardService: AppDashboardService) {
    super();

    this._dashboardService.fetchDashboard().subscribe(dashboard => this._setQuickActionElements(dashboard));
  }

  public onSlideChanged(slideIndex: number) {
    this.title = slideIndex === 0 ? 'base.home.stats.day-title' : 'base.home.stats.week-title';
  }

  public navigateToVisit() {
    this._router.navigateByUrl(CamRoutes.getAbsoluteUrl([EVisitRoute.VISIT]));
  }

  public navigateToReminder() {
    this._router.navigateByUrl(CamRoutes.getAbsoluteUrl([EReminderRoute.REMINDER]));
  }

  private _setQuickActionElements(dashboard: Dashboard): void {
    this.quickActionElements.push(
      ...[
        {
          key: 'visitByDay',
          visible$: of(true),
          progress: this._progression(dashboard.nbVisitsDoneToday, dashboard.nbVisitsToday),
          upTitle: 'base.home.stats.visits',
          downTitle: this._progressionText(dashboard.nbVisitsDoneToday, dashboard.nbVisitsToday),
          onClick: () => this.navigateToVisit(),
        },
        {
          key: 'reminderByDay',
          visible$: of(true),
          progress: this._progression(dashboard.nbRemindersDoneToday, dashboard.nbRemindersToday),
          upTitle: 'base.home.stats.reminders',
          downTitle: this._progressionText(dashboard.nbRemindersDoneToday, dashboard.nbRemindersToday),
          onClick: () => this.navigateToReminder(),
        },
        {
          key: 'visitByWeek',
          visible$: of(true),
          progress: this._progression(dashboard.nbVisitsDoneWeek, dashboard.nbVisitsWeek),
          upTitle: 'base.home.stats.visits',
          downTitle: this._progressionText(dashboard.nbVisitsDoneWeek, dashboard.nbVisitsWeek),
          onClick: () => this.navigateToVisit(),
        },
        {
          key: 'reminderByWeek',
          visible$: of(true),
          progress: this._progression(dashboard.nbRemindersDoneWeek, dashboard.nbRemindersWeek),
          upTitle: 'base.home.stats.reminders',
          downTitle: this._progressionText(dashboard.nbRemindersDoneWeek, dashboard.nbRemindersWeek),
          onClick: () => this.navigateToReminder(),
        },
      ]
    );
  }

  private _progressionText(numberDone: number, numberTotal: number): string {
    return `${numberDone} / ${numberTotal}`;
  }

  private _progression(numberDone: number, numberTotal: number): number {
    return (numberDone / numberTotal) * 100;
  }
}
