import { Component } from '@angular/core';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';

import { ContextForTaskService } from '../context-for-task-service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './task-responsible.component.html',
  styleUrls: ['./task-responsible.component.scss'],
})
export class TaskResponsiblePage extends BasePage {
  constructor(private _contextForTaskService: ContextForTaskService) {
    super();

    this._route.queryParams.subscribe(params => {
      if (params['workingSiteId']) {
        this._contextForTaskService.setContext(params['workingSiteId'], 'workingSite');
      }
    });

    this._registerSubscription(
      this._getPathParams<{ workingSiteId: number | null }>({ workingSiteId: null }).subscribe(params => {
        if (params.workingSiteId) {
          this._contextForTaskService.setContext(params.workingSiteId, 'workingSite');
        }
      })
    );

    this._setPageInformation({
      level: EPageLevel.Main,
      name: '',
    });
  }
}
