<cam-layout-with-bottom-nav>
  <div class="container">
    <cam-title [level]="2">{{ 'not-found.title' | translate }}</cam-title>
    <div class="pb-space-5">
      <cam-local-icon [type]="this.notFoundIcon()" size="xl"></cam-local-icon>
    </div>
    <div class="pb-space-1">
      <cam-button (action)="this.goToHome()">
        {{ 'not-found.cta' | translate }}
      </cam-button>
    </div>
    <div>
      <cam-button [style]="'secondary'" (action)="this.goBack()">
        {{ 'not-found.back' | translate }}
      </cam-button>
    </div>
  </div>
  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-with-bottom-nav>
