<cam-layout-page>
  <cam-layout-header>
    <cam-layout-header-default [title]="this.pageTitle"></cam-layout-header-default>
  </cam-layout-header>
  <cam-layout-content>
    <div *ngIf="this.id">
      <ng-container [ngSwitch]="this.scope">
        <ng-container *ngSwitchCase="'files'">
          <app-offer-request-documents [offerRequestId]="this.id"></app-offer-request-documents>
        </ng-container>
        <ng-container *ngSwitchCase="'pictures'">
          <app-offer-request-pictures [offerRequestId]="this.id"></app-offer-request-pictures>
        </ng-container>
        <ng-container *ngSwitchCase="'visits'">
          <app-offer-request-visits [offerRequestId]="this.id"></app-offer-request-visits>
        </ng-container>
        <ng-container *ngSwitchCase="'comments'">
          <app-offer-request-comments [offerRequestId]="this.id"></app-offer-request-comments>
        </ng-container>
      </ng-container>
    </div>
  </cam-layout-content>
  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
