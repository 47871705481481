import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-title',
  templateUrl: './layout-title.component.html',
  styleUrls: ['./layout-title.component.scss']
})
export class LayoutTitleComponent {

}
