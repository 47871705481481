import { Component, Input, inject } from '@angular/core';

import { Subject, catchError, tap } from 'rxjs';

import { CamRoutes } from '@camelot/menu';
import { ENotificationCode } from '@camelot/notification';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { ETaskRoute } from 'src/app/modules/tasks/routing.module';
import { ReminderLight } from 'src/app/services/clientFollowUp/reminders/dto/reminder-light';
import { WorkingSiteReminderCallResult } from 'src/app/services/clientFollowUp/workingsite-reminders/dto/workingsite-reminder-call-result';
import { WorkingSiteReminderCallSuccessAction } from 'src/app/services/clientFollowUp/workingsite-reminders/dto/workingsite-reminder-call-success-action';
import { AppWorkingSiteReminderCtaFormService } from 'src/app/services/clientFollowUp/workingsite-reminders/form/working-site-reminder-cta-form.service';
import { AppWorkingSiteReminderCtaService } from 'src/app/services/clientFollowUp/workingsite-reminders/workingsite-reminder-cta.service';

@Component({
  selector: 'app-working-site-reminder-call-template',
  templateUrl: './working-site-reminder-call-template.component.html',
  styleUrls: ['./working-site-reminder-call-template.component.scss'],
})
export class WorkingSiteReminderCallTemplateComponent extends BaseComponent {
  @Input()
  workingSiteReminders!: ReminderLight[];

  @Input()
  formSubmit$?: Subject<null>;

  public ctaService: {
    callBack: (id: number, request: { isSuccess: boolean }) => any;
  };

  public formService: {
    getCallForm: () => any;
    formatCallResult: (data: any) => { isSuccess: boolean };
  };

  protected _workingSiteRemindersCtaService = inject(AppWorkingSiteReminderCtaService);
  protected _rhForm = inject(AppWorkingSiteReminderCtaFormService);
  private _workingSiteId: number | null = null;

  constructor() {
    super();
    this._route.params.subscribe(params => {
      this._workingSiteId = +params['id'];
    });
    this.ctaService = {
      callBack: (id: number, request: WorkingSiteReminderCallResult) =>
        this._workingSiteRemindersCtaService.callBack(id, request).pipe(
          tap(() => {
            this._notificationService.addNotification(
              'communication-handler.notification.success',
              ENotificationCode.success
            );
            this.formSubmit$?.next(null);
            if (request.action === WorkingSiteReminderCallSuccessAction.AddNewTask) {
              this._router.navigate(
                [CamRoutes.getAbsoluteUrl([ETaskRoute.TASK, ETaskRoute.LIST, ETaskRoute.RESPONSIBLE])],
                {
                  queryParams: { workingSiteId: this._workingSiteId },
                }
              );
            }
          }),
          catchError((err, obs$) => {
            this._notificationService.addNotification(err, ENotificationCode.error);
            return obs$;
          })
        ),
    };

    this.formService = {
      getCallForm: () => this._rhForm.getCallForm(),
      formatCallResult: (data: any) => this._rhForm.formatCallResult(data),
    };
  }
}
