import { Component } from '@angular/core';

import { filter, map } from 'rxjs/operators';

import { Observable } from 'rxjs';

import { UserLogoNaming } from '@camelot/ui';
import { Permissions } from '@camelot/user';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { ProductionVoucherStatus } from 'src/app/services/workingSite/dto/production-voucher-status';

import { environment } from '../../../../environments/environment';
import { BasePage } from '../../core/abstract/basePage';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomePage extends BasePage {
  get version() {
    return environment.version;
  }

  get canAccessReminders(): boolean {
    return Permissions.hasRole('Advisor') || Permissions.hasRole('Admin') || Permissions.hasRole('SuperAdmin');
  }

  get profileName$(): Observable<{
    profilePictureUrl?: string;
    naming: UserLogoNaming | null;
  }> {
    return this._userProfileService.getUserProfile$.pipe(
      filter(user => !!user),
      map(user => ({
        naming: {
          name: user?.lastName ?? '',
          firstName: user?.firstName ?? '',
          trigram: user?.trigram ?? '',
        },
        profilePictureUrl: user?.profilePictureUrl,
      }))
    );
  }

  public readonly productionVoucherStatus = ProductionVoucherStatus;

  constructor() {
    super();

    this._setPageInformation({
      level: EPageLevel.Main,
      name: '',
    });
  }
}
