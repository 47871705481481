<cam-loader [isLoading]="this.requestState.isLoading()">
  <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
    <cam-empty [isEmpty]="!this.checklistData">
      <div *ngIf="this.checklistData">
        <div class="checklist-header">
          <div class="text">
            <cam-title [level]="2">
              {{ this.checklistData.translatedName }}
            </cam-title>
          </div>
          <div class="save space-between g-space-xs">
            <cam-button (action)="this.askSave()" type="secondary">
              <cam-font-icon name="check-line"></cam-font-icon>
            </cam-button>
            <cam-button (action)="this.askSend()" class="ml-xs" type="secondary">
              <cam-font-icon name="mail"></cam-font-icon>
            </cam-button>
          </div>
        </div>

        <cam-quick-actions
          *ngIf="this.menu"
          [menu]="this.menu"
          [elementPerPage]="this.elementsPerPage"
          [tabSelection]="this.sectionSelected$ | async"
          (tabSelected)="this.tabSelected($event)"
        >
        </cam-quick-actions>
        <cam-form
          class="checklist-forms"
          [inputs]="this.form"
          [canDisplayButton]="false"
          [askOnDestroy]="true"
          [askValidation$]="this.askValidation$"
          (valid)="this.validForm($event)"
        >
        </cam-form>
      </div>
    </cam-empty>
  </cam-error>
</cam-loader>
