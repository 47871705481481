import { Component, Input } from '@angular/core';

import { User } from '@camelot/services';
import { capitalizeFirstLetter } from '@camelot/utils';

import { OfferRequestLight } from '../../../../services/offerRequest/dto/offer-request-light';
import { BaseComponent } from '../../../core/abstract/baseComponent';

@Component({
  selector: 'app-offer-request',
  templateUrl: './offer-request.component.html',
  styleUrls: ['./offer-request.component.scss'],
})
export class OfferRequestComponent extends BaseComponent {
  @Input() offerRequest!: OfferRequestLight;

  get professions(): string[] {
    return this.offerRequest.professions.map(offer => offer.translatedValue);
  }

  get street(): string {
    return capitalizeFirstLetter(this.offerRequest.address.street || '');
  }

  public userLogoData(advisor: User): {
    firstName: string;
    lastName: string;
    trigram?: string;
    picture?: string;
  } {
    return {
      firstName: advisor.naming?.firstName ?? '',
      lastName: advisor.naming?.name ?? '',
      trigram: advisor.naming?.trigram,
    };
  }

  public trigram(advisor: User): string {
    return advisor.naming?.trigram || '';
  }
}
