export enum ChecklistItemType {
  Unknown = 0,
  Information = 1,
  Formula = 2,
  Text = 3,
  Number = 4,
  Image = 5,
  Schema = 6,
  Boolean = 7,
  Select = 8,
}
