<cam-layout-page>
  <cam-layout-header>
    <ng-content select="app-layout-header"></ng-content>
  </cam-layout-header>

  <cam-layout-title>
    <ng-content select="app-layout-title"></ng-content>
  </cam-layout-title>

  <cam-layout-content>
    <ng-content select="app-layout-content"></ng-content>
  </cam-layout-content>

  <cam-layout-nav>
    <ng-content select="app-layout-nav"></ng-content>
  </cam-layout-nav>
</cam-layout-page>
