import { Component, OnDestroy, OnInit } from '@angular/core';

import { Menu } from '@camelot/menu';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { AppMenuQuickActionService } from 'src/app/services/menus/quick-action.service';

import { DetailParams } from '../../types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './visits-detail.component.html',
  styleUrls: ['./visits-detail.component.scss'],
})
export class VisitsDetailPage extends BasePage implements OnInit, OnDestroy {
  public detailParams: DetailParams | null = null;
  public menu: Menu | null = null;

  constructor(private _menuService: AppMenuQuickActionService) {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getPathParams<DetailParams>({ id: 0, type: 0 }).subscribe(detailParams => {
        this._setPageInformation({
          level: EPageLevel.Details,
          name: 'visits.pages.detail.title',
        });

        this.detailParams = detailParams;
        this.menu = this._menuService.getVisitDetailMenu(this.detailParams);
        this._navigationService.setPageData(this.detailParams);
      })
    );
  }
}
