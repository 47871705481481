import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, filter, map, tap } from 'rxjs';
import { LocalStorage } from 'storage-manager-js';

import { Request } from '@camelot/server';
import { MappingApiType } from '@camelot/server';
import { CamBaseService } from '@camelot/server';
import { TranslatedEnumeration, sortByTranslatedValue } from '@camelot/services';
import { fullName } from '@camelot/utils';

import { LeadPayload } from './dto/lead/leadPayload';
import { Person } from './dto/person';
import { PersonLight } from './dto/personLight';

const apiRoutes: MappingApiType = {
  GetPersons: {
    type: 'GET',
    url: '{ApiUrl}/persons?filter={searchTerm}',
  },
  GetPersonsWithProject: {
    type: 'GET',
    url: '{ApiUrl}/persons/withproject',
  },
  GetPerson: {
    type: 'GET',
    url: '{ApiUrl}/persons/{personId}',
  },
  GetCountries: {
    type: 'GET',
    url: '{ApiUrl}/persons/countries',
  },
  PostLead: {
    type: 'POST',
    url: '{ApiUrl}/persons/lead',
  },
};
@Injectable({
  providedIn: 'root',
})
export class AppPersonsService extends CamBaseService {
  public persons$ = new BehaviorSubject<PersonLight[]>([]);
  public personsWithProject$ = new BehaviorSubject<PersonLight[]>([]);
  public _person$ = new BehaviorSubject<{ [id: number]: Person }>({});
  public _countries$ = new BehaviorSubject<TranslatedEnumeration[]>([]);

  constructor() {
    super(apiRoutes);
  }

  public fetchPersonsByTerm(term: string) {
    return this._serverService
      .request<PersonLight[]>(
        new Request({
          type: 'GetPersons',
          content: { searchTerm: term },
          cacheTime: 0,
        })
      )
      .pipe(
        tap(data => {
          this.persons$.next(data);
        })
      );
  }

  public getPersonsWithProject$ = (): Observable<PersonLight[]> =>
    this.personsWithProject$.pipe(
      map(data => data),
      filter(myData => !!myData)
    );

  public fetchPersonsWithProject() {
    return this._serverService
      .request<PersonLight[]>(
        new Request({
          type: 'GetPersonsWithProject',
          cacheTime: 0,
        })
      )
      .pipe(
        tap(data => {
          this.personsWithProject$.next(data);
        })
      );
  }

  public getPerson$ = (id: number): Observable<Person> =>
    this._person$.pipe(
      map(data => data[id]),
      filter(myData => !!myData)
    );

  public postLead(payload: LeadPayload) {
    return this._serverService.request<number>(
      new Request({
        type: 'PostLead',
        content: payload,
      })
    );
  }

  public fetchPerson(id: number) {
    return this._serverService.request<Person>(new Request({ type: 'GetPerson', content: { personId: id } })).pipe(
      filter(entity => !!entity),
      tap(entity => {
        const entities = this._person$.getValue();
        entities[id] = entity;
        this._person$.next(entities);
        this.saveInLocalStorage(entity);
      })
    );
  }

  public fetchCountries$() {
    return this._serverService
      .request<TranslatedEnumeration[]>(
        new Request({
          type: 'GetCountries',
          cacheTime: -1,
        })
      )
      .pipe(
        map(entities => sortByTranslatedValue(entities)),
        tap(entities => {
          this._countries$.next(entities);
        })
      );
  }

  private saveInLocalStorage(entity: Person) {
    let storedPersons: { id: number; fullName: string; phoneNumber: string | null; storageTime: Date }[] = JSON.parse(
      LocalStorage.get('storedPersons') ?? '[]'
    );
    for (let person of storedPersons) {
      if (person.id === entity.id) {
        person.storageTime = new Date();
        this.orderAndSelect(storedPersons);
        LocalStorage.set('storedPersons', JSON.stringify(storedPersons));
        return;
      }
    }
    storedPersons.push({
      id: entity.id,
      fullName: fullName({ name: entity.naming?.firstName ?? '', firstName: entity.naming?.name ?? null }),
      phoneNumber: entity.phoneNumber,
      storageTime: new Date(),
    });
    this.orderAndSelect(storedPersons);
    LocalStorage.set('storedPersons', JSON.stringify(storedPersons.slice(0, 6)));
  }

  private orderAndSelect(items: { id: number; storageTime: Date }[]) {
    items.sort((a, b) => {
      const dateA = new Date(a.storageTime);
      const dateB = new Date(b.storageTime);
      return dateB.getTime() - dateA.getTime();
    });
  }
}
