<cam-layout-page>
  <cam-layout-header>
    <cam-layout-header-default [title]="this.pageTitle"></cam-layout-header-default>
  </cam-layout-header>

  <cam-layout-content>
    <div *ngIf="this.detailParams">
      <ng-container [ngSwitch]="this.scope">
        <ng-container *ngIf="this.scope === 'subtasks'">
          <app-task-subtasks [id]="this.detailParams.id"></app-task-subtasks>
        </ng-container>
        <ng-container *ngIf="this.scope === 'pictures'">
          <app-task-pics [id]="this.detailParams.id"></app-task-pics>
        </ng-container>
        <ng-container *ngIf="this.scope === 'files'">
          <app-tasks-documents [id]="this.detailParams.id"> </app-tasks-documents>
        </ng-container>
        <ng-container *ngIf="this.scope === 'comments'">
          <app-task-comments [id]="this.detailParams.id"> </app-task-comments>
        </ng-container>
      </ng-container>
    </div>
  </cam-layout-content>

  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-page>
