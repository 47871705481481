import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-first-level',
  templateUrl: './layout-first-level.component.html',
  styleUrls: ['./layout-first-level.component.scss']
})
export class LayoutFirstLevelComponent {

}
