import { Component } from '@angular/core';

import { AppMenuSecondService } from 'src/app/services/menus/second.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  template: `
    <app-layout-first-level>
      <app-layout-header>
        <cam-title [level]="2">{{ 'tasks.pages.list.title' | translate }}</cam-title>
      </app-layout-header>
      <app-layout-content>
        <cam-menu-navigation
          [menu]="this.menu"
          container="tags"
          [options]="{ spaceElement: null }"
        ></cam-menu-navigation>
        <router-outlet></router-outlet>
      </app-layout-content>
      <app-layout-nav>
        <app-menu-main></app-menu-main>
      </app-layout-nav>
    </app-layout-first-level>
  `,
})
export class TasksListBasePage {
  public menu = this._menuService.getTaskMenu();

  constructor(private _menuService: AppMenuSecondService) {}
}
