import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';
import { AuthGuard } from '@camelot/user';

import { VisitsSubDetailPage } from './pages/detail/sub/visits-sub-detail.component';
import { VisitsDetailPage } from './pages/detail/visits-detail.component';
import { VisitsAgendaPage } from './pages/planned/agenda/visits-agenda.component';
import { VisitChronologicalPage } from './pages/planned/chronological/visit-chronological.component';
import { VisitsPlannedPage } from './pages/planned/visits-planned.component';

export enum EVisitRoute {
  VISIT = 'visit',
  LIST = 'list',
  AGENDA = 'agenda',
  CHRONOLOGICAL = 'chronological',
  DETAIL = 'detail',
  SUBDETAIL = 'subdetail',
}

export class AppVisitsRoutingModule {
  public static loadRoutes() {
    CamRoutes.addRoute({
      key: EVisitRoute.VISIT,
      url: 'visits',
      children: [
        {
          key: EVisitRoute.LIST,
          url: 'list',
          children: [
            {
              key: EVisitRoute.AGENDA,
              url: 'agenda',
            },
            {
              key: EVisitRoute.CHRONOLOGICAL,
              url: 'chronological',
            },
          ],
        },
        {
          key: EVisitRoute.DETAIL,
          url: 'detail/:type/:id',
        },
        {
          key: EVisitRoute.SUBDETAIL,
          url: 'detail/:type/:id/:scope',
        },
      ],
    });
  }

  public static getRouting(): Routes {
    return [
      // only for redirection
      {
        path: '',
        redirectTo: CamRoutes.getAbsoluteUrl([EVisitRoute.VISIT, EVisitRoute.LIST, EVisitRoute.CHRONOLOGICAL]),
        pathMatch: 'full',
      },
      {
        path: CamRoutes.getUrl([EVisitRoute.VISIT, EVisitRoute.LIST]),
        component: VisitsPlannedPage,
        canActivate: [AuthGuard],
        children: [
          {
            path: CamRoutes.getUrl([EVisitRoute.VISIT, EVisitRoute.LIST, EVisitRoute.AGENDA]),
            component: VisitsAgendaPage,
            canActivate: [AuthGuard],
          },
          {
            path: CamRoutes.getUrl([EVisitRoute.VISIT, EVisitRoute.LIST, EVisitRoute.CHRONOLOGICAL]),
            component: VisitChronologicalPage,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: CamRoutes.getUrl([EVisitRoute.VISIT, EVisitRoute.DETAIL]),
        component: VisitsDetailPage,
      },
      {
        path: CamRoutes.getUrl([EVisitRoute.VISIT, EVisitRoute.SUBDETAIL]),
        component: VisitsSubDetailPage,
      },
    ];
  }
}
