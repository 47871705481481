import { Component, Input } from '@angular/core';

import { differenceInMinutes } from 'date-fns';

import { CamRoutes } from '@camelot/menu';
import { diffInHourAndMinutes, fullName } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { EVisitRoute } from 'src/app/modules/visits/routing.module';
import { VisitDetail } from 'src/app/services/visits/dto/visit-detail';

import {
  getCardClass,
  getVisitStatusTranslation,
  getVisitStatusType,
  getVisitTypeTranslation,
  getVisitTypeType,
} from '../../../visits/helpers';

@Component({
  selector: 'app-card-visit-day',
  templateUrl: './card-visit-day.component.html',
  styleUrls: ['./card-visit-day.component.scss'],
})
export class CardVisitDayComponent extends BaseComponent {
  @Input() fullHeight!: boolean;
  @Input() visitByDay!: VisitDetail;
  @Input() highlight!: boolean;

  get visitTypeType() {
    return getVisitTypeType(this.visitByDay);
  }

  get visitTypeTranslation() {
    return getVisitTypeTranslation(this.visitByDay);
  }
  get visitStatusType() {
    return getVisitStatusType(this.visitByDay);
  }
  get visitStatusTranslation() {
    return getVisitStatusTranslation(this.visitByDay);
  }

  get cardClass() {
    return getCardClass(this.visitByDay);
  }

  get diffInMinutes() {
    return differenceInMinutes(new Date(this.visitByDay.endDate), new Date(this.visitByDay.startDate));
  }
  get diffInHourAndMinutes(): { h: string; m: string } {
    return diffInHourAndMinutes(this.visitByDay.startDate, this.visitByDay.endDate);
  }

  get displayFullInfo(): boolean {
    return this.diffInMinutes > 30;
  }

  get visitName(): string {
    if (this.visitByDay.client.naming) {
      return fullName(this.visitByDay.client.naming);
    }
    return '';
  }

  constructor() {
    super();
  }

  public cardSelected(): void {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([EVisitRoute.VISIT, EVisitRoute.DETAIL], {
        id: this.visitByDay.id,
        type: this.visitByDay.type,
      })
    );
  }
}
