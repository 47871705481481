import { Injectable } from '@angular/core';

import { compareDesc } from 'date-fns';
import { BehaviorSubject, Observable, filter, map, tap } from 'rxjs';

import { Request } from '@camelot/server';
import { MappingApiType } from '@camelot/server';
import { CamBaseService } from '@camelot/server';
import { TranslatedEnumeration, sortByTranslatedValue } from '@camelot/services';

import { OfferRequest } from './dto/offer-request';
import { OfferRequestLight } from './dto/offer-request-light';

const apiRoutes: MappingApiType = {
  GetOfferRequest: {
    type: 'GET',
    url: '{ApiUrl}/offerrequests/{offerRequestId}',
  },
  GetOfferRequestsFromClient: {
    type: 'GET',
    url: '{ApiUrl}/offerrequests/{clientId}/offerrequests',
  },
  GetOrigins: {
    type: 'GET',
    url: '{ApiUrl}/offerrequests/origins',
  },
  GetProfessions: {
    type: 'GET',
    url: '{ApiUrl}/offerrequests/professions',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AppOfferRequestService extends CamBaseService {
  public offerRequest$ = new BehaviorSubject<{ [id: number]: OfferRequest }>({});
  public offerRequestsFromClient$ = new BehaviorSubject<{ [id: number]: OfferRequestLight[] }>({});
  public origins$ = new BehaviorSubject<TranslatedEnumeration[]>([]);
  public professions$ = new BehaviorSubject<TranslatedEnumeration[]>([]);

  constructor() {
    super(apiRoutes);
  }

  public getOfferRequest$ = (id: number): Observable<OfferRequest> =>
    this.offerRequest$.pipe(
      map(data => data[id]),
      filter(myData => !!myData)
    );

  public fetchOfferRequest$(id: number): Observable<OfferRequest> {
    return this._serverService
      .request<OfferRequest>(
        new Request({
          type: 'GetOfferRequest',
          content: { offerRequestId: id },
        })
      )
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this.offerRequest$.getValue();
          entities[id] = entity;
          this.offerRequest$.next(entities);
        })
      );
  }

  public getOfferRequestsFromClient$ = (id: number): Observable<OfferRequestLight[]> =>
    this.offerRequestsFromClient$.pipe(
      map(data => data[id]),
      filter(myData => !!myData),
      map(offers => offers.sort((a, b) => compareDesc(new Date(a.createdTime || ''), new Date(b.createdTime || ''))))
    );

  public fetchOfferRequestsFromClient$(clientId: number) {
    return this._serverService
      .request<OfferRequestLight[]>(
        new Request({
          type: 'GetOfferRequestsFromClient',
          content: { clientId: clientId },
        })
      )
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this.offerRequestsFromClient$.getValue();
          entities[clientId] = entity;
          this.offerRequestsFromClient$.next(entities);
        })
      );
  }

  public fetchOrigins$() {
    return this._serverService
      .request<TranslatedEnumeration[]>(
        new Request({
          type: 'GetOrigins',
          cacheTime: -1,
        })
      )
      .pipe(
        map(entities => sortByTranslatedValue(entities)),
        tap(entities => {
          this.origins$.next(entities);
        })
      );
  }

  public fetchProfessions$() {
    return this._serverService
      .request<TranslatedEnumeration[]>(
        new Request({
          type: 'GetProfessions',
          cacheTime: -1,
        })
      )
      .pipe(
        map(entities => sortByTranslatedValue(entities)),
        tap(entities => {
          this.professions$.next(entities);
        })
      );
  }
}
