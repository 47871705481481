import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';

import { ProspectionsListPage } from './pages/prospections-list/prospections-list.component';
import { RemindersListPage } from './pages/reminders-list/reminders-list.component';
import { WorkingSiteRemindersListPage } from './pages/workingsite-reminders-list/workingsite-reminders-list.component';

export enum EReminderRoute {
  REMINDER = 'reminder',
  REMINDERS = 'reminders',
  PROSPECTIONS = 'prospections',
  WORKINGSITEREMINDERS = 'workings-site-reminders',
}

export class AppRemindersRoutingModule {
  public static loadRoutes() {
    CamRoutes.addRoute({
      key: EReminderRoute.REMINDER,
      url: 'reminders',
      children: [
        {
          key: EReminderRoute.REMINDERS,
          url: 'reminders',
        },
        {
          key: EReminderRoute.PROSPECTIONS,
          url: 'prospections',
        },
        {
          key: EReminderRoute.WORKINGSITEREMINDERS,
          url: 'working-site-reminders',
        },
      ],
    });
  }

  public static getRouting(): Routes {
    return [
      // only for redirection
      {
        path: '',
        redirectTo: CamRoutes.getAbsoluteUrl([EReminderRoute.REMINDER, EReminderRoute.REMINDERS]),
        pathMatch: 'full',
      },
      {
        path: CamRoutes.getUrl([EReminderRoute.REMINDER, EReminderRoute.REMINDERS]),
        component: RemindersListPage,
      },
      {
        path: CamRoutes.getUrl([EReminderRoute.REMINDER, EReminderRoute.PROSPECTIONS]),
        component: ProspectionsListPage,
      },
      {
        path: CamRoutes.getUrl([EReminderRoute.REMINDER, EReminderRoute.WORKINGSITEREMINDERS]),
        component: WorkingSiteRemindersListPage,
      },
    ];
  }
}
