import { Injectable } from '@angular/core';

import { of } from 'rxjs';

import { CamRoutes, Menu, MenuBase, MenuIcon } from '@camelot/menu';

import { EReminderRoute } from 'src/app/modules/reminders/routing.module';
import { EStockRoute } from 'src/app/modules/stock/van/routing.module';
import { ETaskRoute } from 'src/app/modules/tasks/routing.module';
import { ETierRoute } from 'src/app/modules/tiers/routing.module';
import { EVisitRoute } from 'src/app/modules/visits/routing.module';

@Injectable({
  providedIn: 'root',
})
export class AppMenuSecondService {
  constructor() {}

  public getVisitMenu(): Menu {
    const menu: MenuBase[] = [
      new MenuIcon({
        key: 'agenda',
        link: CamRoutes.getAbsoluteUrl([EVisitRoute.VISIT, EVisitRoute.LIST, EVisitRoute.AGENDA]),
        icon: 'list',
        order: 1,
        queryParamsHandling: 'preserve',
      }),
      new MenuIcon({
        key: 'chronological',
        link: CamRoutes.getAbsoluteUrl([EVisitRoute.VISIT, EVisitRoute.LIST, EVisitRoute.CHRONOLOGICAL]),
        icon: 'grid',
        order: 2,
        queryParamsHandling: 'preserve',
      }),
    ];
    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'horizontal',
    });
  }

  public getTaskMenu(): Menu {
    const menu: MenuBase[] = [
      new MenuBase({
        key: 'plan',
        label: 'menu.task.responsible',
        link: CamRoutes.getAbsoluteUrl([ETaskRoute.TASK, ETaskRoute.LIST, ETaskRoute.RESPONSIBLE]),
        order: 1,
        defaultOpen: true,
        visible$: of(true),
      }),
      new MenuBase({
        key: 'toPLan',
        label: 'menu.task.requester',
        link: CamRoutes.getAbsoluteUrl([ETaskRoute.TASK, ETaskRoute.LIST, ETaskRoute.REQUESTER]),
        order: 2,
        visible$: of(true),
      }),
    ];
    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'horizontal',
    });
  }

  public getWarehouseMenu(data: { warehouseId: number }): Menu {
    return {
      elements: [
        new MenuIcon({
          key: 'ExitVouchers',
          label: 'stock.pages.exit-vouchers',
          link: CamRoutes.getAbsoluteUrl([EStockRoute.STOCK, EStockRoute.SUBDETAIL], {
            id: data.warehouseId,
            scope: 'exit-vouchers',
          }),
          order: 1,
          icon: 'download',
        }),
        new MenuIcon({
          key: 'ReturnVouchers',
          label: 'stock.pages.return-vouchers',
          link: CamRoutes.getAbsoluteUrl([EStockRoute.STOCK, EStockRoute.SUBDETAIL], {
            id: data.warehouseId,
            scope: 'return-vouchers',
          }),
          order: 2,
          icon: 'upload',
        }),
      ],
      direction: 'horizontal',
    };
  }

  public getClientFollowUpMenu(): Menu {
    return {
      elements: [
        new MenuIcon({
          key: 'Prospections',
          label: 'client-follow-up.menu.prospections',
          link: CamRoutes.getAbsoluteUrl([EReminderRoute.REMINDER, EReminderRoute.PROSPECTIONS]),
          order: 1,
        }),
        new MenuIcon({
          key: 'Reminders',
          label: 'client-follow-up.menu.reminders',
          link: CamRoutes.getAbsoluteUrl([EReminderRoute.REMINDER, EReminderRoute.REMINDERS]),
          order: 2,
        }),
        new MenuIcon({
          key: 'WorkingSiteReminders',
          label: 'client-follow-up.menu.workingsite-reminders',
          link: CamRoutes.getAbsoluteUrl([EReminderRoute.REMINDER, EReminderRoute.WORKINGSITEREMINDERS]),
          order: 3,
        }),
      ].sort((a, b) => a.order - b.order),
      direction: 'responsive',
    };
  }

  public getTiersMenu(): Menu {
    return {
      elements: [
        new MenuIcon({
          key: 'Internal',
          label: 'tiers.menu.internal',
          link: CamRoutes.getAbsoluteUrl([ETierRoute.TIER, ETierRoute.INTERNAL, ETierRoute.LIST]),
          order: 1,
        }),
        new MenuIcon({
          key: 'External',
          label: 'tiers.menu.external',
          link: CamRoutes.getAbsoluteUrl([ETierRoute.TIER, ETierRoute.EXTERNAL, ETierRoute.LIST]),
          order: 2,
        }),
      ].sort((a, b) => a.order - b.order),
      direction: 'responsive',
    };
  }
}
