import { Injectable } from '@angular/core';

import { filter, map, tap } from 'rxjs/operators';

import { BehaviorSubject } from 'rxjs';

import { Request } from '@camelot/server';
import { MappingApiType } from '@camelot/server';
import { CamBaseService } from '@camelot/server';
import { TranslatedEnumeration } from '@camelot/services';

import { Type } from '../visits/dto/type';
import { Checklist } from './dto/checklist';

const apiRoutes: MappingApiType = {
  GetChecklists: {
    type: 'GET',
    url: '{ApiUrl}/visits/{type}/{id}/checklists',
  },
  GetChecklistByProfession: {
    type: 'GET',
    url: '{ApiUrl}/visits/{visitType}/{visitId}/checklists/{id}',
  },
  GetChecklistForTaskJobContext: {
    type: 'GET',
    url: '{ApiUrl}/visits/{visitType}/{visitId}/taskJob/checklists/{id}',
  },
  PostChecklist: {
    type: 'POST',
    url: '{ApiUrl}/visits/{visitType}/{visitId}/checklists/{id}',
  },
  PostChecklistForTaskJobContext: {
    type: 'POST',
    url: '{ApiUrl}/visits/{visitType}/{visitId}/taskJob/checklists/{id}',
  },
  SendChecklist: {
    type: 'POST',
    url: '{ApiUrl}/visits/{visitType}/{visitId}/checklists/{id}/send',
  },
  SendChecklistForTaskJobContext: {
    type: 'POST',
    url: '{ApiUrl}/visits/{visitType}/{visitId}/taskJob/checklists/{id}/send',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AppChecklistsService extends CamBaseService {
  private _checklists$ = new BehaviorSubject<{
    [index: string]: TranslatedEnumeration[];
  }>({});

  private _checklist$ = new BehaviorSubject<{
    [index: string]: Checklist;
  }>({});

  constructor() {
    super(apiRoutes);
  }

  public getChecklists$(id: number) {
    return this._checklists$.pipe(
      map(data => data[id]),
      filter(myData => !!myData)
    );
  }

  public fetchCheckLists$(data: { type: Type; id: number }) {
    return this._serverService
      .request<TranslatedEnumeration[]>(new Request({ type: 'GetChecklists', content: data, cacheTime: 0 }))
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this._checklists$.getValue();
          entities[data.id] = entity;
          this._checklists$.next(entities);
        })
      );
  }

  public getChecklist$(id: number, visitId: number) {
    return this._checklist$.pipe(
      map(data => data[this._getChecklistKey(id, visitId)]),
      filter(myData => !!myData)
    );
  }
  public getChecklistSection$(id: number, visitId: number) {
    return this.getChecklist$(id, visitId).pipe(
      map(cl =>
        cl.sections
          .sort((a, b) => a.order - b.order)
          .map(section => ({
            id: section.id,
            name: section.name,
          }))
      )
    );
  }
  public fetchCheckList$(data: { visitType: Type; visitId: number; id: number }) {
    if (data.visitType == Type.Sav || data.visitType == Type.Maintenance) {
      return this._serverService
        .request<Checklist>(new Request({ type: 'GetChecklistForTaskJobContext', content: data }))
        .pipe(
          filter(data => !!data),
          tap(entity => {
            const entities = this._checklist$.getValue();
            entities[this._getChecklistKey(data.id, data.visitId)] = entity;
            this._checklist$.next(entities);
          })
        );
    } else {
      return this._serverService
        .request<Checklist>(new Request({ type: 'GetChecklistByProfession', content: data }))
        .pipe(
          filter(data => !!data),
          tap(entity => {
            const entities = this._checklist$.getValue();
            entities[this._getChecklistKey(data.id, data.visitId)] = entity;
            this._checklist$.next(entities);
          })
        );
    }
  }

  private _getChecklistKey(id: number, visitId: number) {
    return `${id}-${visitId}`;
  }

  public postCheckList$(data: Checklist & { visitType: Type; visitId: number }) {
    if (data.visitType == Type.Sav || data.visitType == Type.Maintenance) {
      return this._serverService.request<void>(
        new Request({ type: 'PostChecklistForTaskJobContext', content: data, cacheTime: -1 })
      );
    } else {
      return this._serverService.request<void>(new Request({ type: 'PostChecklist', content: data, cacheTime: -1 }));
    }
  }

  public sendCheckList$(data: { id: number; visitType: Type; visitId: number }) {
    if (data.visitType == Type.Sav || data.visitType == Type.Maintenance) {
      return this._serverService.request<void>(
        new Request({ type: 'SendChecklistForTaskJobContext', urlData: data, cacheTime: -1 })
      );
    } else {
      return this._serverService.request<void>(new Request({ type: 'SendChecklist', urlData: data, cacheTime: -1 }));
    }
  }
}
