import { Component, OnInit } from '@angular/core';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';

import { DetailParams, PageScope } from '../../types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './tasks-sub-detail.component.html',
  styleUrls: ['./tasks-sub-detail.component.scss'],
})
export class TasksSubDetailPage extends BasePage implements OnInit {
  public detailParams: DetailParams | null = null;

  public scope: PageScope = '';

  constructor() {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getPathParams<DetailParams & { scope: PageScope }>({ id: 0, scope: '' }).subscribe(detailParams => {
        this.detailParams = detailParams;
        this.scope = detailParams.scope;

        this._setPageInformation({
          level: EPageLevel.Sub,
          name: 'tasks.pages.sub.title.' + detailParams.scope,
        });

        this._navigationService.setPageData(this.detailParams);
      })
    );
  }
}
