<app-layout-first-level>
  <app-layout-header>
    <div>
      <app-calendar-navigation
        [view]="this.view"
        [viewDate]="this.viewDate"
        (viewDateChanged)="this.viewDateChanged($event)"
        (selectedMonth)="this.showMonth()"
      ></app-calendar-navigation>
    </div>
  </app-layout-header>
  <app-layout-content>
    <div class="title-container align-center">
      <cam-title [level]="4">{{ 'visits.list.by-day.title' | translate }}</cam-title>
      <cam-menu-navigation [menu]="this.menu" container="tags" [options]="{ spaceElement: null }"></cam-menu-navigation>
    </div>
    <router-outlet></router-outlet>
  </app-layout-content>
  <app-layout-nav>
    <app-menu-main></app-menu-main>
  </app-layout-nav>
</app-layout-first-level>

<ng-template #monthVisitsTemplate>
  <app-month-bottom [viewDate]="this.viewDate" (closeBottom)="this.closeBottom($event)"></app-month-bottom>
</ng-template>
