import { Injectable } from '@angular/core';

import { Observable, map, of } from 'rxjs';
import slugify from 'slugify';

import {
  IInputBase,
  IInputDropdown,
  IInputDynamic,
  IInputImages,
  IInputRadio,
  IInputSchema,
  InputBase,
  InputDynamic,
  InputFactory,
  InputPanel,
} from '@camelot/form-model';
import { CamIconType } from '@camelot/icons';
import { FileData, FileStructure } from '@camelot/utils';

import { Picture } from '../files/dto/picture';
import { Checklist } from './dto/checklist';
import { BaseChecklistItemQuestion } from './dto/checklist-item/base-checklist-item-question';
import { ChecklistSelect } from './dto/checklist-item/checklist-item-select';
import { ChecklistItemType } from './dto/checklist-item/checklist-item-type';
import { IChecklistItem } from './dto/checklist-item/i-checklist-item';
import { IChecklistItemWithSubQuestions } from './dto/checklist-item/i-checklist-item-with-sub-questions';
import { Group } from './dto/group';
import { Section } from './dto/section';
import { SubQuestion } from './dto/sub-question';

type VisitOption = { upload: (data: FileStructure[]) => Promise<Picture[]>; get$: Observable<FileData[]> };
@Injectable({
  providedIn: 'root',
})
export class AppChecklistFormService {
  constructor() {}

  public getChecklistForm(checklist: Checklist, sectionSelected$: Observable<string | null>, visitOption: VisitOption) {
    const input: InputBase<any>[] = checklist.sections.map(section => {
      let children: InputBase<any>[] = [];

      if (section.repeatable) {
        children = [
          new InputDynamic({
            key: section.id.toString(),
            label: '',
            template: section.groups.map(group => ({
              type: 'InputPanel',
              options: {
                key: group.id.toString(),
                label: group.name,
                class: '',
                containerClass: ['highlight-title'],
                templateChildren: () =>
                  group.questions.map(question => this._getInputByQuestion(question, visitOption)),
              },
            })),
            inputsGroup: section.repeatableSections?.reduce<IInputDynamic['inputsGroup']>(
              (inputsGroup = {}, section) => {
                const id = slugify(section.name.toString());
                inputsGroup[id] = [
                  new InputPanel({
                    key: section.id.toString(),
                    label: section.name,
                    class: '',
                    containerClass: ['highlight-title'],
                    children: section.groups
                      .sort((a, b) => a.order - b.order)
                      .map(
                        group =>
                          new InputPanel({
                            key: group.id.toString(),
                            label: group.name,
                            class: 'pt-15',
                            containerClass: ['highlight-title', 'with-separator'],
                            children: group.questions.map(question => this._getInputByQuestion(question, visitOption)),
                          })
                      ),
                  }),
                ];
                return inputsGroup;
              },
              {}
            ),
          }),
        ];
      } else {
        children = section.groups
          .sort((a, b) => a.order - b.order)
          .map(
            group =>
              new InputPanel({
                key: group.id.toString(),
                label: group.name,
                containerClass: ['highlight-title', 'with-separator'],
                class: '',
                children: group.questions.map(question => this._getInputByQuestion(question, visitOption)),
              })
          );
      }

      return new InputPanel({
        key: section.id.toString(),
        class: '',
        visible$: sectionSelected$.pipe(map(tab => tab === section.id.toString())),
        bindStatusToVisible: false,
        children: children,
      });
    });

    return input;
  }

  public fillChecklistByForm(checklist: Checklist, form: any): Checklist {
    const getFormSubKey = (section: Section, repSection: Section) => {
      return section.id.toString() + '-' + slugify(repSection.name.toString());
    };
    const getForm = (section: Section, repSection: Section) => {
      if (!form[section.id.toString()]) {
        return null;
      }
      return [getFormSubKey(section, repSection)] || null;
    };
    for (let section of checklist.sections) {
      if (section.repeatable && form[section.id.toString()]) {
        // remove unused elements
        section.repeatableSections = section.repeatableSections?.filter(repSection => !!getForm(section, repSection));

        //update correct element
        for (let repSection of section.repeatableSections || []) {
          this._fillGroups(repSection.groups, getForm(section, repSection));
        }

        // add new elements
        const newKey = Object.keys(form[section.id.toString()]);
        const currentListKeys = section.repeatableSections?.map(repSection => getFormSubKey(section, repSection));

        const addedKeys = newKey.filter(key => !currentListKeys?.includes(key));

        for (let key of addedKeys) {
          const newSection: Section = {
            id: section.repeatableSections?.length || 1,
            name: key,
            order: 0,
            repeatable: false,
            groups: section.groups,
            repeatableSections: [],
          };

          this._fillGroups(newSection.groups, form[section.id.toString()][key]);

          section.repeatableSections?.push(newSection);
        }
      } else {
        this._fillGroups(section.groups, form);
      }
    }
    return checklist;
  }

  private _getInputByQuestion(
    question: IChecklistItem | IChecklistItemWithSubQuestions,
    visitOption: VisitOption
  ): InputBase<any> {
    if (question.hasOwnProperty('subQuestions') && (<IChecklistItemWithSubQuestions>question).subQuestions) {
      return this._getInputWithSubQuestion(question as IChecklistItemWithSubQuestions, visitOption);
    }
    return this._getInput(question, visitOption);
  }

  private _getInput(question: IChecklistItem, visitOption: VisitOption): InputBase<any> {
    const options: IInputBase<any> = {
      key: question.id.toString(),
      label: question.label,
      class: 'pb-4',
      value: question.hasOwnProperty('response') ? (<BaseChecklistItemQuestion>question).response : null,
    };
    switch (question.type) {
      case ChecklistItemType.Information:
        return InputFactory.getInput('InputLabel', options);
      case ChecklistItemType.Text:
        return InputFactory.getInput('InputTextBox', options);
      case ChecklistItemType.Number:
        return InputFactory.getInput('InputNumber', options);
      case ChecklistItemType.Image:
        const imageOptions: IInputImages = {
          ...options,
          ...{
            update: visitOption.upload,
            files$: visitOption.get$,
          },
        };
        return InputFactory.getInput('InputImages', imageOptions);
      case ChecklistItemType.Schema:
        const schemaOptions: IInputSchema = {
          ...options,
          ...{
            update: visitOption.upload,
          },
        };
        return InputFactory.getInput('InputSchema', schemaOptions);
      case ChecklistItemType.Boolean:
        const radio: IInputRadio<boolean> = {
          ...options,
          options: of([
            {
              id: true,
              name: 'radio.yes',
              icon: CamIconType.CheckLight,
            },
            {
              id: false,
              name: 'radio.no',
              icon: CamIconType.CancelLight,
            },
          ]),
        };
        return InputFactory.getInput('InputRadio', radio);
      case ChecklistItemType.Select:
        const selectQuestion = question as ChecklistSelect;

        if (!selectQuestion.multiChoice && options.value) {
          options.value = options.value[0];
        }
        const selectOptions: IInputDropdown<any> = {
          ...options,
          ...{
            showNothingOption: true,
            value: options.value,
            multiple: selectQuestion.multiChoice,
            options: of(selectQuestion.options.map(option => ({ id: option.id.toString(), name: option.label }))),
          },
        };
        return InputFactory.getInput('InputDropdown', selectOptions);
      default:
        return InputFactory.getInput('InputLabel', options);
    }
  }

  private _getInputWithSubQuestion(baseQuestion: IChecklistItemWithSubQuestions, visitOption: VisitOption) {
    const baseInput = this._getInput(baseQuestion, visitOption);

    return new InputPanel({
      key: baseQuestion.id.toString(),
      class: '',
      children: [
        baseInput,
        ...baseQuestion.subQuestions.map(
          subQuestion =>
            new InputPanel({
              key: baseQuestion.id.toString(),
              class: '',
              children: subQuestion.questions.map(question => this._getInputByQuestion(question, visitOption)),
              visible$: baseInput.changeValue$.pipe(map(value => this._isVisibleChildren(subQuestion, value))),
            })
        ),
      ],
    });
  }

  private _isVisibleChildren(subQuestion: SubQuestion, response: any): boolean {
    if (response === null || response === undefined) {
      return false;
    }

    const values: string[] = Array.isArray(response) ? response : [response.toString()];

    return values.some(value => subQuestion.values.includes(value));
  }

  private _fillGroups(groups: Group[], form: any) {
    for (let group of groups) {
      for (let question of group.questions) {
        this._fillQuestion(question, form);
      }
    }
  }
  private _fillQuestion(question: IChecklistItem | IChecklistItemWithSubQuestions, form: any) {
    if (!question.hasOwnProperty('response')) {
      return question;
    }
    let value = form[question.id.toString()];

    if (value && question.type === ChecklistItemType.Select && !Array.isArray(value)) {
      value = [value];
    }
    (<BaseChecklistItemQuestion>question).response = value === undefined ? null : value;

    if (question.hasOwnProperty('subQuestions')) {
      for (let sub of (<IChecklistItemWithSubQuestions>question).subQuestions || []) {
        for (let subQuestion of sub.questions) {
          this._fillQuestion(subQuestion, form);
        }
      }
    }
    return question;
  }
}
