import { Component, OnDestroy, OnInit } from '@angular/core';

import { CamRoutes } from '@camelot/menu';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { AppUsersService } from 'src/app/services/users/users.service';

import { ETierRoute } from '../../../routing.module';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './tiers-internal.component.html',
  styleUrls: ['./tiers-internal.component.scss'],
})
export class TiersInternalPage extends BasePage implements OnInit, OnDestroy {
  public searchTerm: string = '';

  constructor(public usersService: AppUsersService) {
    super();
  }

  ngOnInit(): void {
    this._registerSubscription(
      this._getQueryParams({ search: '' }).subscribe(({ search }) => {
        this._setPageInformation({
          level: EPageLevel.Main,
          name: 'tiers.pages.internal.title',
        });
        if (search) this.searchTerm = search;
      })
    );
  }

  public goToDetail(id: number) {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([ETierRoute.TIER, ETierRoute.EXTERNAL, ETierRoute.DETAIL], {
        id,
      })
    );
  }
}
