import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';
import { AuthGuard, FeatureGuard } from '@camelot/user';

import { OfferRequestDetailPage } from './pages/detail/offer-request-detail.component';
import { OfferRequestSubDetailPage } from './pages/detail/offer-request-sub-detail/offer-request-sub-detail.component';

export enum EOfferRequestRoute {
  OfferRequest = 'offer-request',
  DETAIL = 'detail',
  SUBDETAIL = 'subdetail',
}

export class AppOfferRequestsRoutingModule {
  public static loadRoutes() {
    CamRoutes.addRoute({
      key: EOfferRequestRoute.OfferRequest,
      url: 'offerrequest',
      children: [
        {
          key: EOfferRequestRoute.DETAIL,
          url: 'detail/:id',
        },
        {
          key: EOfferRequestRoute.SUBDETAIL,
          url: 'detail/:id/:scope',
        },
      ],
    });
  }

  public static getRouting(): Routes {
    return [
      {
        path: CamRoutes.getUrl([EOfferRequestRoute.OfferRequest, EOfferRequestRoute.DETAIL]),
        component: OfferRequestDetailPage,
        canActivate: [AuthGuard, FeatureGuard],
        data: { feature: 'advisor', level: 'read' },
      },
      {
        path: CamRoutes.getUrl([EOfferRequestRoute.OfferRequest, EOfferRequestRoute.SUBDETAIL]),
        component: OfferRequestSubDetailPage,
        canActivate: [AuthGuard, FeatureGuard],
        data: { feature: 'advisor', level: 'read' },
      },
    ];
  }
}
