import { Component, Input } from '@angular/core';

import { CamRoutes } from '@camelot/menu';
import { fullName } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { ETierRoute } from 'src/app/modules/tiers/routing.module';
import { PersonLight } from 'src/app/services/persons/dto/personLight';

@Component({
  selector: 'app-person-card-detail',
  templateUrl: './person-card-detail.component.html',
  styleUrls: ['./person-card-detail.component.scss'],
})
export class PersonCardDetailComponent extends BaseComponent {
  @Input()
  person!: PersonLight;

  get fullName(): string {
    if (this.person.naming) {
      return fullName(this.person.naming);
    }
    return '';
  }

  constructor() {
    super();
  }

  public navigateToClient() {
    this._router.navigateByUrl(
      CamRoutes.getAbsoluteUrl([ETierRoute.TIER, ETierRoute.EXTERNAL, ETierRoute.DETAIL], {
        id: this.person.id,
      })
    );
  }
}
