import { Component } from '@angular/core';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BasePage } from 'src/app/modules/core/abstract/basePage';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './workingsite-reminders-list.component.html',
  styleUrls: ['./workingsite-reminders-list.component.scss'],
})
export class WorkingSiteRemindersListPage extends BasePage {
  constructor() {
    super();

    this._setPageInformation({
      level: EPageLevel.Main,
      name: '',
    });
  }
}
