import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, filter, map, tap } from 'rxjs';
import { LocalStorage } from 'storage-manager-js';

import { CamBaseService, MappingApiType, Request } from '@camelot/server';
import { User } from '@camelot/services';
import { fullName } from '@camelot/utils';

const apiRoutes: MappingApiType = {
  GetUsers: {
    type: 'GET',
    url: '{ApiUrl}/users?filter={searchTerm}',
  },
  GetUser: {
    type: 'GET',
    url: '{ApiUrl}/users/{userId}',
  },
};
@Injectable({
  providedIn: 'root',
})
export class AppUsersService extends CamBaseService {
  public _users$ = new BehaviorSubject<{ [id: number]: User }>([]);

  public users$ = new BehaviorSubject<User[]>([]);

  constructor() {
    super(apiRoutes);
  }

  public fetchUser(id: number) {
    return this._serverService.request<User>(new Request({ type: 'GetUser', content: { userId: id } })).pipe(
      filter(entity => !!entity),
      tap(entity => {
        const entities = this._users$.getValue();
        entities[id] = entity;
        this._users$.next(entities);
        this.saveInLocalStorage(entity);
      })
    );
  }

  private saveInLocalStorage(entity: User) {
    let storedUsers: { id: number; fullName: string; phoneNumber: string | null; storageTime: Date }[] = JSON.parse(
      LocalStorage.get('storedUsers') ?? '[]'
    );
    for (let user of storedUsers) {
      if (user.id === entity.id) {
        user.storageTime = new Date();
        this.orderAndSelect(storedUsers);
        LocalStorage.set('storedUsers', JSON.stringify(storedUsers));
        return;
      }
    }
    storedUsers.push({
      id: entity.id,
      fullName: fullName({ name: entity.naming?.firstName ?? '', firstName: entity.naming?.name ?? null }),
      phoneNumber: entity.phoneNumber,
      storageTime: new Date(),
    });
    this.orderAndSelect(storedUsers);
    LocalStorage.set('storedUsers', JSON.stringify(storedUsers.slice(0, 6)));
  }

  private orderAndSelect(items: { id: number; storageTime: Date }[]) {
    items.sort((a, b) => {
      const dateA = new Date(a.storageTime);
      const dateB = new Date(b.storageTime);
      return dateB.getTime() - dateA.getTime();
    });
  }

  public fetchUsersByTerm(term: string) {
    return this._serverService
      .request<User[]>(
        new Request({
          type: 'GetUsers',
          content: { searchTerm: term },
          cacheTime: 0,
        })
      )
      .pipe(
        tap(data => {
          this.users$.next(data);
        })
      );
  }

  public fetchList$() {
    return this.fetchUsersByTerm('');
  }

  public getUser$ = (id: number): Observable<User> =>
    this._users$.pipe(
      map(data => data[id]),
      filter(myData => !!myData)
    );
}
