import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Observable } from 'rxjs';

import { InputTextarea } from '@camelot/form-model';

import { BaseComponent } from '../../../../core/abstract/baseComponent';

@Component({
  selector: 'app-comments-post',
  templateUrl: './comments-post.component.html',
  styleUrls: ['./comments-post.component.scss'],
})
export class CommentsPostComponent extends BaseComponent implements OnInit {
  @Input()
  reset!: Observable<void>;
  @Input()
  isInEditMode!: boolean;
  @Input()
  enableEditMode$!: Observable<string>;

  @Output()
  commentPosted: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  editionCancelled: EventEmitter<void> = new EventEmitter<void>();

  public isEditMode = false;

  public comment = new InputTextarea({
    label: '',
  });

  get title() {
    return this.isEditMode ? 'comments.list.edit-comment' : 'comments.post.new';
  }
  constructor() {
    super();
    this.comment.createFormControl();
  }

  ngOnInit() {
    this._registerSubscription(this.reset.subscribe(() => (this.comment.value = '')));
    this._registerSubscription(this.enableEditMode$.subscribe(commentMessage => this._passInEditMode(commentMessage)));
  }

  public onCommentPosted() {
    if (!this.comment.value) {
      return;
    }
    this.commentPosted.emit(this.comment.value);
    this.isInEditMode = false;
  }

  public cancelEditMode() {
    this.editionCancelled?.emit();
    this.isInEditMode = false;
  }

  private _passInEditMode(commentMessage: string): void {
    this.comment.value = commentMessage;
    this.isInEditMode = true;
  }
}
