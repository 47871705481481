import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { CamCoreModule } from '@camelot/core';
import { CamFilesExtendedModule } from '@camelot/files-extended';
import { CamFormModule } from '@camelot/form-basic';
import { CamFormInputsModule } from '@camelot/form-input';
import { CamIconsModule } from '@camelot/icons';
import { CamMenuModule } from '@camelot/menu';
import {
  CamCardModule,
  CamContainerModule,
  CamLayoutModule,
  CamListModule,
  CamSwiperModule,
  CamUiModule,
  SwiperComponent,
} from '@camelot/ui';
import { CamDirectivePipeModule } from '@camelot/utils';

import { AddressCardComponent } from './card/address/address-card.component';
import { ContactsCardComponent } from './card/contacts/contacts-card.component';
import { CardVisitDayComponent } from './card/daily-visit/card-visit-day.component';
import { MailCardComponent } from './card/mail-card/mail-card.component';
import { OfferRequestComponent } from './card/offer-request/offer-request.component';
import { PersonCardComponent } from './card/person/card.component';
import { PhonesCardComponent } from './card/phones-card/phones-card.component';
import { CardPersonTagCivilityComponent } from './card/tag-civility/card-person-tag-civility.component';
import { WorkingSiteComponent } from './card/working-site/card-working-site.component';
import { ComingSoonPage } from './coming-soon/coming-soon.component';
import { CommentsListComponent } from './comments/list/comments-list.component';
import { CommentsGroupComponent } from './comments/list/group/comments-group.component';
import { CommentsItemComponent } from './comments/list/item/comments-item.component';
import { OnRenderDirective } from './comments/list/on-render.directive';
import { CommentsPostComponent } from './comments/list/post/comments-post.component';
import { HeaderComponent } from './header/header.component';
import { MenuMainComponent } from './menu/main/menu-main.component';
import { SecondNavComponent } from './menu/second/menu-second.component';
import { MenuUserComponent } from './menu/user/menu-user.component';
import { NotFoundPage } from './not-found/not-found.component';
import { PersonCardDetailComponent } from './person/card/detail/person-card-detail.component';
import { PicsComponent } from './pics/pics.component';
import { PicViewComponent } from './pics/view/pic-view.component';
import { PriceOfferDateListComponent } from './price-offer/date-list/price-offer-date-list.component';
import { ProspectionCardComponent } from './prospection/card/prospection-card.component';
import { ProspectionBottom } from './prospection/dialog/prospection-dialog.component';
import { ProspectionCallTemplateComponent } from './prospection/dialog/templates/call/prospection-call-template.component';
import { ProspectionMailTemplateComponent } from './prospection/dialog/templates/mail/prospection-mail-template.component';
import { ProspectionSnoozeTemplateComponent } from './prospection/dialog/templates/snooze/prospection-snooze-template.component';
import { ReminderCardBaseComponent } from './reminder/card/base/reminder-card-base.component';
import { ReminderCardFullComponent } from './reminder/card/full/reminder-card-full.component';
import { ReminderBottomModal } from './reminder/dialog/reminder-dialog.component';
import { ReminderCallTemplateComponent } from './reminder/dialog/templates/call/reminder-call-template.component';
import { ReminderMailTemplateComponent } from './reminder/dialog/templates/mail/reminder-mail-template.component';
import { ReminderReturnTemplateComponent } from './reminder/dialog/templates/return/reminder-return-template.component';
import { ReminderSnoozeTemplateComponent } from './reminder/dialog/templates/snooze/reminder-snooze-template.component';
import { ReminderRemindingDateComponent } from './reminder/reminding-date/reminder-reminding-date.component';
import { WorkingSiteReminderCardComponent } from './workingsite-reminder/card/workingsite-reminder-card.component';
import { WorkingSiteReminderCallTemplateComponent } from './workingsite-reminder/dialog/templates/call/working-site-reminder-call-template.component';
import { WorkingSiteReminderMailTemplateComponent } from './workingsite-reminder/dialog/templates/mail/working-site-reminder-mail-template.component';
import { WorkingSiteReminderSnoozeTemplateComponent } from './workingsite-reminder/dialog/templates/snooze/working-site-reminder-snooze-template.component';
import { WorkingSiteReminderBottom } from './workingsite-reminder/dialog/working-site-reminder-dialog.component';

@NgModule({
  declarations: [
    AddressCardComponent,
    CardPersonTagCivilityComponent,
    CardVisitDayComponent,
    ComingSoonPage,
    CommentsGroupComponent,
    CommentsItemComponent,
    CommentsListComponent,
    CommentsPostComponent,
    ContactsCardComponent,
    HeaderComponent,
    MailCardComponent,
    MenuMainComponent,
    MenuUserComponent,
    NotFoundPage,
    OfferRequestComponent,
    OnRenderDirective,
    PersonCardComponent,
    PersonCardDetailComponent,
    PhonesCardComponent,
    PicsComponent,
    PicViewComponent,
    PriceOfferDateListComponent,
    ProspectionBottom,
    ProspectionCallTemplateComponent,
    ProspectionCardComponent,
    ProspectionMailTemplateComponent,
    ProspectionSnoozeTemplateComponent,
    ReminderBottomModal,
    ReminderCallTemplateComponent,
    ReminderCardBaseComponent,
    ReminderCardFullComponent,
    ReminderMailTemplateComponent,
    ReminderRemindingDateComponent,
    ReminderReturnTemplateComponent,
    ReminderSnoozeTemplateComponent,
    SecondNavComponent,
    WorkingSiteComponent,
    WorkingSiteReminderBottom,
    WorkingSiteReminderCallTemplateComponent,
    WorkingSiteReminderCardComponent,
    WorkingSiteReminderMailTemplateComponent,
    WorkingSiteReminderSnoozeTemplateComponent,
  ],
  imports: [
    CamCardModule,
    CamContainerModule,
    CamCardModule,
    CamCoreModule,
    CamDirectivePipeModule,
    CamFilesExtendedModule,
    CamFilesExtendedModule,
    CamFormInputsModule,
    CamFormModule,
    CamIconsModule,
    CamLayoutModule,
    CamListModule,
    CamMenuModule,
    CamSwiperModule,
    CamUiModule,
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    SwiperComponent,
  ],
  exports: [
    AddressCardComponent,
    CardPersonTagCivilityComponent,
    CardVisitDayComponent,
    ComingSoonPage,
    CommentsListComponent,
    ContactsCardComponent,
    HeaderComponent,
    MailCardComponent,
    MenuMainComponent,
    MenuUserComponent,
    NotFoundPage,
    OfferRequestComponent,
    PersonCardComponent,
    PersonCardDetailComponent,
    PhonesCardComponent,
    PicsComponent,
    PicViewComponent,
    PriceOfferDateListComponent,
    ProspectionBottom,
    ProspectionCardComponent,
    ReminderBottomModal,
    ReminderCardBaseComponent,
    ReminderCardFullComponent,
    ReminderRemindingDateComponent,
    SecondNavComponent,
    WorkingSiteComponent,
    WorkingSiteReminderBottom,
    WorkingSiteReminderCardComponent,
  ],
})
export class AppBaseModule {}
